import React from "react";
import { Segment, Container, Button } from "semantic-ui-react";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import Rodape from "../../layouts/Rodape";
import { Link } from "react-router-dom";

const PoliticaPrivacidade = () => (
  <div>
    <MenuHorizontal />
    <Container style={styles.container} className="">
      <h1>Política de Privacidade</h1>
      <Segment>
        <p style={styles.p1}>
          A Aplicação em questão possibilitará aos usuários terem acesso a diversas informações relacionadas a condomínios, como unidades, proprietários ou condôminos das unidades e votações, criadas por ele.
        </p>
      </Segment>
      <Segment>
        <h3>Informações fornecidas pelo usuário</h3>
        <p style={styles.p2}>
          Aplicativo requer cadastro do seu usuário e poderá coletar determinadas informações automaticamente, incluindo, entre outros, o tipo, ID exclusivo, o endereço IP de seu dispositivo móvel, o sistema operacional, o tipo de navegador de Internet móvel e informações sobre a maneira como você usa o aplicativo. Ao realizar uma visita ao aplicativo móvel, podemos usar a tecnologia GPS (ou outra tecnologia similar) para determinar sua localização atual, a fim de determinar a cidade em que você está localizado e exibir um mapa de localização com anúncios relevantes, produtos em promoção e estabelecimentos mais próximos. Não compartilharemos sua localização atual com outros usuários ou parceiros. Se você não quiser que usemos seu local para as finalidades acima, não permita o serviço de localização para o aplicativo móvel. Além do GPS, é necessário permitir acesso à câmera do dispositivo, para efetuar a leitura de código de barra, fotografar produtos e atualizar foto de perfil, tais funcionalidades permitem o acesso a informação externa para dentro do aplicativo.
          </p>
      </Segment>
      <Segment>
        <h3>Os terceiros veem e/ou têm acesso às informações obtidas pelo Aplicativo?</h3>
        <p style={styles.p2}>
          Sim. Nós compartilharemos suas informações com terceiros apenas das formas descritas nesta declaração de privacidade. Podemos divulgar informações fornecidas e coletadas automaticamente pelo usuário: conforme exigido por lei, como para cumprir uma intimação ou processo legal semelhante; quando acreditamos de boa fé que a divulgação é necessária para proteger nossos direitos, proteger sua segurança ou a segurança de outras pessoas, investigar fraudes ou responder a uma solicitação do governo; com nossos prestadores de serviços confiáveis ​​que trabalham em nosso nome, não têm um uso independente das informações que divulgamos a eles e concordamos em aderir às regras estabelecidas nesta declaração de privacidade.
          </p>
      </Segment>
      <Segment>
        <h3>Quais são os meus direitos de exclusão?</h3>
        <p style={styles.p2}>
          Você pode parar toda a coleta de informações pelo Aplicativo facilmente, desinstalando o Aplicativo. Você pode usar os processos de desinstalação padrão que podem estar disponíveis como parte de seu dispositivo móvel.
          </p>
      </Segment>
      <Segment>
        <h3>Política de Retenção de Dados, Gerenciando Suas Informações</h3>
        <p style={styles.p2}>
          Nós manteremos os dados fornecidos pelo usuário enquanto você usar o aplicativo e por um tempo razoável depois disso. Reteremos as informações coletadas automaticamente por até 24 meses e, a partir dessa data, poderemos armazená-las de forma agregada. Se você quiser que nós excluamos os dados fornecidos pelo usuário, entre em contato conosco contato@app4all.com.br e nós responderemos em um tempo razoável. Observe que alguns ou todos os dados fornecidos pelo usuário podem ser necessários para que o aplicativo funcione corretamente.
          </p>
      </Segment>
      <Segment>
        <h3>Crianças</h3>
        <p style={styles.p2}>
          Nós não usamos a aplicação para solicitar conscientemente dados ou mercado para crianças com idade inferior a 13 anos. Se um pai ou responsável tomar conhecimento de que seu filho nos forneceu informações sem o seu consentimento, ele deverá entrar em contato conosco pelo e-mail contato@app4all.com.br. Nós excluiremos essas informações de nossos arquivos em um tempo razoável.
          </p>
      </Segment>
      <Segment>
        <h3>Segurança</h3>
        <p style={styles.p2}>
          Estamos preocupados em proteger a confidencialidade de suas informações. Fornecemos proteções físicas, eletrônicas e processuais para proteger as informações que processamos e mantemos. Por exemplo, limitamos o acesso a essas informações a funcionários e contratados autorizados que precisam conhecer essas informações para operar, desenvolver ou melhorar nossa aplicação. Por favor, esteja ciente de que, embora nos empenhemos em fornecer segurança razoável para as informações que processamos e mantemos, nenhum sistema de segurança pode evitar todas as possíveis violações de segurança.
          </p>
      </Segment>
      <Segment>
        <h3>Alterar</h3>
        <p style={styles.p2}>
          Esta Política de Privacidade pode ser atualizada de tempos em tempos por qualquer motivo. Vamos notificá-lo de quaisquer alterações à nossa Política de Privacidade, publicando a nova Política de Privacidade aqui e informando você via e-mail ou mensagem de texto. Recomenda-se que você consulte esta Política de Privacidade regularmente para quaisquer alterações, pois o uso continuado é considerado aprovação de todas as alterações.
          </p>
      </Segment>
      <Segment>
        <h3>Sua permissão</h3>
        <p style={styles.p2}>
          Ao utilizar a aplicação, você está consentindo com o processamento de suas informações conforme estabelecido nesta Política de Privacidade agora e conforme alterado por nós. "Processamento" significa utilizar os cookies em um computador/dispositivo portátil utilizando as informações de qualquer maneira, incluindo, mas não se limitando a coletar, armazenar, excluir, usar, combinar e divulgar informações, todas as quais atividades serão realizadas. Se você reside fora do Brasil, suas informações serão transferidas, processadas e armazenadas nos padrões de privacidade do Brasil.
        </p>
        <p style={styles.p2}>
          Entre em contato se tiver alguma dúvida sobre privacidade durante o uso do aplicativo ou tiver dúvidas sobre nossas práticas, entre em contato conosco por meio de e-mail em contato@app4all.com.br
        </p>
      </Segment>
      <Segment stacked>
        <p>Data da última revisão: 20 de novembro de 2019</p>
      </Segment>
      <br />
      <div className="text-center">
        <Button as={Link} to="/" className="btn-ui-1" size="large" >Voltar para tela inicial</Button>
      </div>
    </Container>
    <Rodape />
  </div>
);

const styles = {
  p1: {
    fontSize: "16px"
  },
  p2: {
    marginTop: "20px"
  },
  row: {
    marginBottom: "60px"
  },
  container: {
    padding: "40px 10px"
  }
}

export default PoliticaPrivacidade