import React from 'react';
import {Modal} from "semantic-ui-react";

const ModalEnviarEmail = ({ btnConfirmAction, message, header, btnConfirmLabel, btnCancelLabel, modalOpen, toggleFunction }) => {

    return (
        <Modal
            header={header || 'Confirmação'}
            open={modalOpen}
            onClose={toggleFunction}
            content={message}
            actions={[
                {
                    key: 'close',
                    content: btnCancelLabel || 'Não',
                    className: 'btn-ui-1 btn-ui-1-basic'
                }, {
                    key: 'done',
                    content: btnConfirmLabel || 'Sim',
                    className: 'btn-ui-1',
                    onClick: btnConfirmAction
                }
            ]}
        />
    );
}

export default ModalEnviarEmail;