import React, { useState, useEffect } from 'react';
import { Transition, Segment, Button, Icon, Message } from 'semantic-ui-react';
import * as FormValidator from '../../../utils/formValidator';
import Erro from '../../layouts/mensagens/Erro';
import Pergunta from './Pergunta';
import api from '../../../api';

const EditarVotacaoPerguntas = ({ visible, votacao, condominio, votacaoPossuiVoto, handleItemClick, disabledFields }) => {
  const [perguntas, setPerguntas] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (Object.keys(votacao).length > 0 && visible) {
        setLoading(true);
        loadPerguntas();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [votacao, visible]
  );

  const loadPerguntas = async () => {
    try {
      setPerguntas(await api.pergunta.findForEdit(votacao._id));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrors(e.data.errors);
    }
  };

  const adicionarPergunta = async () => {
    setLoading(true);
    try {
      const result = await api.pergunta.create({ votacao: { _id: votacao._id, condominio: condominio._id } });
      setPerguntas(p => [...p, result]);
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(false);
  };

  const updatePergunta = pergunta => setPerguntas(p => p.map(it => it._id === pergunta._id ? pergunta : it));

  const excluirPergunta = async pergunta => {
    setLoading(true);
    try {
      const result = await api.pergunta.findByIdAndRemove(pergunta._id);
      setPerguntas(p => p.filter(it => it._id !== result._id));
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(false);
  };

  return (
    <Transition
      animation="fade up"
      duration={{ show: 700, hide: 0 }}
      visible={visible}
      transitionOnMount={true}
    >
      <div>
        {votacaoPossuiVoto && (
          <Message
            style={{ marginTop: 20 }}
            color="purple"
            header="Atenção"
            content="Esta votação já possui votos! Redobre a atenção ao atualizar os dados."
          />
        )}
        <Segment>
          {perguntas.length ? (
            <div>
              <div>
                <div style={{ marginBottom: "10px" }}>
                  <p><strong>Perguntas / Pautas</strong></p>
                </div>
                {perguntas.map((pergunta, i) => (
                  <Pergunta
                    key={pergunta._id}
                    pergunta={pergunta}
                    votacao={votacao}
                    submitExcluir={excluirPergunta}
                    updatePergunta={updatePergunta}
                    numeroPergunta={i + 1}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div style={styles.vazio}>
              <Erro text={errors.votacaoPerguntas} />
              <p>As perguntas / pautas criadas aparecerão aqui</p>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            {(perguntas.some(p => FormValidator.isVazio(p.titulo)) || perguntas.some(p => p.respostas.length === 0)) && (
              <h4 style={{ marginTop: "40px" }}>Finalize as perguntas / pautas que você começou antes de adicionar uma nova</h4>
            )}
            <Button
              className="btn-ui-1"
              onClick={adicionarPergunta}
              style={{ margin: "20px 15px 30px 15px" }}
              loading={loading}
              disabled={loading || perguntas.some(p => FormValidator.isVazio(p.titulo)) || perguntas.some(p => p.respostas.length === 0)}
              size="large"
            >
              <Icon name="plus" />Adicionar Nova Pergunta / Pauta
            </Button>
          </div>
        </Segment>
      </div>
    </Transition>
  );
};

const styles = {
  vazio: {
    padding: "30px 10px 20px 10px",
    textAlign: "center",
    fontSize: "13pt",
    fontWeight: 100,
    opacity: .7,
  },
};

export default EditarVotacaoPerguntas;
