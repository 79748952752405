import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = condominio => ({
  type: 'CREATE_CONDOMINIO_SUCCESS',
  condominio
});

const findSuccess = (dados) => ({
  type: 'FIND_CONDOMINIOS_SUCCESS',
  dados,
});

const findByIdSuccess = condominio => ({
  type: 'FIND_BY_ID_CONDOMINIO_SUCCESS',
  condominio
});

const findByIdAndUpdateSuccess = condominio => ({
  type: 'UPDATE_CONDOMINIO_SUCCESS',
  condominio
});

const validarQuantidadeSuccess = ({ habilitado }) => ({
  type: 'VALIDAR_QUANTIDADE_CONDOMINIO_SUCCESS',
  habilitado
});

const addError = errors => ({
  type: 'ADD_CONDOMINIO_ERROR',
  errors
});

export const create = (dados) => async dispatch => {
  return api.condominio.create(dados).then(data => {
    return dispatch(createSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findById = id => async dispatch => {
  return api.condominio.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const find = () => async dispatch => {
  return api.condominio.find().then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.condominio.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const validarQuantidade = () => async dispatch => {
  return api.condominio.validarQuantidade().then(data => {
    return dispatch(validarQuantidadeSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findEnderecoByCep = cep => async dispatch => {
  return api.global.findEnderecoByCep(cep).then(res => {
    if (res.ok && !res.data.erro) {
      return res.data;
    } else {
      dispatch(addError({ cep: "CEP não encontrado" }));
    }
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};
