import React, { useState, useRef, useEffect } from 'react';
import { Segment, Popup, Icon, Form, Input, Responsive, Button, Divider, Modal } from 'semantic-ui-react';
import Erro from '../../layouts/mensagens/Erro';
import Resposta from './Resposta';
import * as FormValidator from '../../../utils/formValidator';
import api from '../../../api';
import Documento from './Documento';
import Link from './Link';
import debounce from 'lodash.debounce';

const DEBOUNCE_TIMEOUT = 700;

const Pergunta = ({ votacao, pergunta, numeroPergunta, updatePergunta, submitExcluir }) => {
  const debounceRef = useRef();

  const [isRemove, setIsRemove] = useState(false);
  const [loading, setLoading] = useState({ resposta: false, documento: false, link: false });
  const [salvando, setSalvando] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [formPergunta, setFormPergunta] = useState(pergunta);
  const [formResposta, setFormResposta] = useState({ pergunta: pergunta._id, titulo: '' });
  const [formDocumento, setFormDocumento] = useState({ pergunta: pergunta._id, nome: '' });
  const [formLink, setFormLink] = useState({ pergunta: pergunta._id, url: '', nome: '' });
  const [showAlertEdit, setShowAlertEdit] = useState(false);

  useEffect(
    () => {
      debounceRef.current = debounce(onDebounce, DEBOUNCE_TIMEOUT);

      return () => debounceRef.current.flush();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDebounce = async e => {
    if (FormValidator.isVazio(e.value)) {
      setErrors({ titulo: 'Digite uma pergunta' });
    } else {
      setSalvando(true);
      try {
        const result = await api.pergunta.findByIdAndUpdate(pergunta._id, {
          titulo: e.value,
          votacao: votacao._id,
        });
        updatePergunta({ ...pergunta, titulo: result.titulo, blocos: result.blocos });
        setErrors({});
      } catch (e) {
        if (e.status === 403) {
          setShowAlertEdit(true);
          updatePergunta({ ...pergunta, totalVotos: 1 });
        } else {
          setErrors(e.data.errors);
        }
      }
      setSalvando(false);
    }
  };

  const onChangePergunta = ({ target: { name, value } }) => {
    setFormPergunta(p => ({ ...p, [name]: value }));
    debounceRef.current({ name, value });
  };

  const onChangeResposta = ({ target: { name, value } }) => setFormResposta(p => ({ ...p, [name]: value }));
  const onChangeDocumento = ({ target: { name, value } }) => setFormDocumento(p => ({ ...p, [name]: value }));
  const onChangeLink = ({ target: { name, value } }) => setFormLink(p => ({ ...p, [name]: value }));

  const onChangeFile = ({ target: { files } }) => {
    if (files && files[0] && files[0].type === 'application/pdf') {
      if (FormValidator.sizeLimit(files[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
        setFile(files[0]);
        setErrors({});
      } else {
        setErrors({ documento: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb` });
      }
    }
  };

  const adicionarResposta = async () => {
    if (FormValidator.isVazio(formResposta.titulo)) {
      setErrors({ tituloResposta: 'Digite uma possível resposta' });
    } else {
      setLoading(p => ({ ...p, resposta: true }));
      try {
        const result = await api.resposta.create(formResposta);
        updatePergunta({ ...pergunta, respostas: [ ...pergunta.respostas, result ] });
        setFormResposta(p => ({ ...p, titulo: '' }));
      } catch (e) {
        if (e.status === 403) {
          setShowAlertEdit(true);
          updatePergunta({ ...pergunta, totalVotos: 1 });
        } else {
          setErrors(e.data.errors);
        }
      }
      setLoading(p => ({ ...p, resposta: false }));
    }
  };

  const editarResposta = async resposta => {
    setLoading(p => ({ ...p, resposta: true }));
    try {
      const result = await api.resposta.findByIdAndUpdate(resposta._id, resposta);
      updatePergunta({
        ...pergunta,
        respostas: pergunta.respostas.map(
          it => it._id === result._id ? { _id: result._id, titulo: result.titulo } : it
        )
      });
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(p => ({ ...p, resposta: false }));
  };

  const excluirResposta = async resposta => {
    setLoading(p => ({ ...p, resposta: true }));
    try {
      const result = await api.resposta.findByIdAndRemove(resposta._id);
      updatePergunta({
        ...pergunta,
        respostas: pergunta.respostas.filter(it => it._id !== result._id)
      });
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(p => ({ ...p, resposta: false }));
  };

  const validarDocumento = () => {
    const errs = {};

    if (FormValidator.isVazio(formDocumento.nome)) {
      errs.nomeDocumento = 'Digite um nome para o documento';
    }

    if (!file) {
      errs.documento = 'Você precisa selecionar um documento';
    }

    return errs;
  };

  const buildFormDocumento = () => {
    const formData = new FormData();

    formData.append("arquivo", file);

    Object.keys(formDocumento).forEach(item => {
      formData.append(item, formDocumento[item]);
    });

    return formData;
  };

  const adicionarDocumento = async () => {
    const errs = validarDocumento();
    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      setLoading(p => ({ ...p, documento: true }));
      try {
        const result = await api.documento.create(buildFormDocumento());
        setFile(null);
        setFormDocumento(p => ({ ...p, nome: '' }));
        updatePergunta({
          ...pergunta,
          documentos: [
            ...pergunta.documentos,
            result,
          ]
        });
      } catch (e) {
        setErrors(e.data.errors);
      }
      setLoading(p => ({ ...p, documento: false }));
    }
  };

  const excluirDocumento = async documento => {
    setLoading(p => ({ ...p, documento: true }));
    try {
      const result = await api.documento.findByIdAndRemove(documento._id);
      updatePergunta({
        ...pergunta,
        documentos: pergunta.documentos.filter(it => it._id !== result._id)
      });
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(p => ({ ...p, documento: false }));
  };

  const validarLink = () => {
    const errs = {};

    if (FormValidator.isVazio(formLink.nome)) {
      errs.nomeLink = 'Preencimento do nome é obrigatório';
    }

    if (FormValidator.isVazio(formLink.url)) {
      errs.urlLink = 'Preencimento da url é obrigatório';
    } else if (!FormValidator.isUrl(formLink.url)) {
      errs.urlLink = 'Url inválida';
    }

    return errs;
  };

  const adicionarLink = async () => {
    const errs = validarLink();
    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      setLoading(p => ({ ...p, link: true }));
      try {
        const result = await api.pergunta.addLink(pergunta._id, formLink);
        setFormLink(p => ({ ...p, url: '', nome: '' }));
        updatePergunta({
          ...pergunta,
          links: [
            ...pergunta.links,
            result,
          ]
        });
      } catch (e) {
        setErrors(e.data.errors);
      }
      setLoading(p => ({ ...p, link: false }));
    }
  };

  const excluirLink = async link => {
    setLoading(p => ({ ...p, link: true }));
    try {
      const result = await api.pergunta.removeLink(pergunta._id, link._id);
      updatePergunta({
        ...pergunta,
        links: pergunta.links.filter(it => it._id !== result._id)
      });
    } catch (e) {
      setErrors(e.data.errors);
    }
    setLoading(p => ({ ...p, link: false }));
  };

  const excluir = () => setIsRemove(true);
  const cancelar = () => setIsRemove(false);
  const confirmarExclusao = () => submitExcluir(pergunta);

  const podeEditar = !pergunta.totalVotos || pergunta.totalVotos === 0;

  const getFooterMsg = () => (
    <p style={{ fontSize: "9.5pt" }}>
      {salvando ? (
        <><Icon name="refresh" loading={salvando} /> Salvando</>
      ) : (
        <>Alterações Salvas</>
      )}
    </p>
  );

  const getContent = () => (
    <div style={{ marginBottom: "20px" }}>
      <Segment color="violet">
        <Modal
          size="small"
          header="Erro ao atualizar dados"
          open={showAlertEdit}
          onClose={() => setShowAlertEdit(false)}
          content="Esta pergunta já possui votos. Não é permitido a alteração do titulo ou respostas!"
          actions={[
            {
              key: 'close',
              content: 'OK',
              className: 'btn-ui-1 btn-ui-1-basic'
            }
          ]}
        />
        {podeEditar && (
          <Popup
            content="Excluir Pergunta / Pauta"
            position="top center"
            size="small"
            trigger={
              <span className="ui-action-excluir" onClick={excluir}>
                <Icon name="trash alternate outline" />
              </span>
            }
          />
        )}
        <div>
          <p><strong>{numeroPergunta}ª&nbsp; Pergunta / Pauta</strong></p>
          <Form style={{ marginBottom: "10px" }}>
            <Form.Field error={!!errors.titulo}>
              <Input
                type="text"
                name="titulo"
                placeholder="Pergunta / Pauta"
                value={formPergunta.titulo}
                onChange={onChangePergunta}
                readOnly={!podeEditar}
              />
              <Erro text={errors.titulo} />
            </Form.Field>
          </Form>
          <p style={{ marginBottom: 5, fontSize: "9.5pt" }}><b>Possíveis Respostas</b></p>
          {podeEditar && (
            <Form autoComplete="off" onSubmit={adicionarResposta}>
              <Form.Group>
                <Form.Field width={16} error={!!errors.tituloResposta}>
                  <Input
                    type="text"
                    name="titulo"
                    placeholder="Digite uma possível resposta e clique em adicionar"
                    value={formResposta.titulo}
                    onChange={onChangeResposta}
                    disabled={loading.resposta}
                    readOnly={loading.resposta}
                  />
                </Form.Field>
                <Responsive minWidth={768}>
                  <Popup
                    trigger={
                      <Button
                        onClick={adicionarResposta}
                        className="btn-ui-1"
                        loading={loading.resposta}
                        disabled={loading.resposta}
                      >
                        Adicionar
                      </Button>
                    }
                    content="Adicionar Resposta"
                    position="top center"
                  />
                </Responsive>
                <Responsive maxWidth={767}>
                  <Button
                    onClick={adicionarResposta}
                    className="btn-ui-1"
                    icon="plus"
                    loading={loading.resposta}
                    disabled={loading.resposta}
                  />
                </Responsive>
                <Erro text={errors.tituloResposta} />
              </Form.Group>
            </Form>
          )}
          {pergunta.respostas.length > 0 && pergunta.respostas.map(resposta => (
            <Resposta
              key={Math.random()}
              resposta={resposta}
              pergunta={pergunta}
              submitEditar={editarResposta}
              submitExcluir={excluirResposta}
              podeEditar={podeEditar}
            />
          ))}
          <Divider />
          <p style={{ fontSize: "9.5pt", marginBottom: "5px" }}><b>Documentos</b></p>
          <Form autoComplete="off">
            <Form.Group>
              <Form.Field error={!!errors.documento} width={9} style={{ marginBottom: "5px" }}>
                <Popup
                  trigger={
                    <label htmlFor={pergunta._id} className="label-ui-arquivo">
                      <span>
                        {file ? <><Icon name="file pdf outline" /> {file.name}</> : <><Icon name="paperclip" /> Anexar documento PDF</> }
                      </span>
                      <Input
                        type="file"
                        id={pergunta._id}
                        name="arquivo"
                        style={{ display: 'none' }}
                        onChange={onChangeFile}
                        accept="application/pdf"
                        loading={loading.documento}
                        disabled={loading.documento}
                      />
                    </label>
                  }
                  content="Selecione um documento PDF para associar a essa pergunta / pauta"
                  size="small"
                />
                <Erro text={errors.documento} />
              </Form.Field>
              <Form.Field error={!!errors.nomeDocumento} width={7}>
                <Input
                  type="text"
                  name="nome"
                  placeholder="Nome para identificar o documento"
                  value={formDocumento.nome}
                  onChange={onChangeDocumento}
                  disabled={loading.documento}
                  readOnly={loading.documento}
                />
              </Form.Field>
              <Responsive minWidth={768}>
                <Popup
                  trigger={
                    <Button
                      onClick={adicionarDocumento}
                      className="btn-ui-1"
                      size="medium"
                      loading={loading.documento}
                      disabled={loading.documento}
                    >
                      Adicionar
                    </Button>
                  }
                  content="Adicionar Documento"
                  position="top center"
                />
              </Responsive>
              <Responsive maxWidth={767}>
                <Popup
                  trigger={
                    <Button
                      onClick={adicionarDocumento}
                      className="btn-ui-1"
                      size="small"
                      icon="plus"
                      loading={loading.documento}
                      disabled={loading.documento}
                    />
                  }
                  content="Adicionar Documento"
                  size="small"
                  position="top center"
                />
              </Responsive>
              <Erro text={errors.nomeDocumento} />
            </Form.Group>
          </Form>
          {pergunta.documentos.length > 0 && pergunta.documentos.map(documento => (
            <Documento
              key={Math.random()}
              documento={documento}
              pergunta={pergunta}
              submitExcluir={excluirDocumento}
            />
          ))}
          <Divider />
          <p style={{ fontSize: "9.5pt", marginBottom: "5px" }}><b>Links de acesso ou transmissão</b></p>
          <Form autoComplete="off">
            <Form.Group>
              <Form.Field error={!!errors.urlLink} width={9}>
                <Input
                  type="text"
                  name="url"
                  placeholder="Link de acesso ou transmissão Zoom, Youtube, Meet, Teams, Skype, entre outros."
                  value={formLink.url}
                  onChange={onChangeLink}
                  disabled={loading.link}
                  readOnly={loading.link}
                />
                <Erro text={errors.urlLink} />
              </Form.Field>
              <Form.Field error={!!errors.nomeLink} width={6}>
                <Input
                    type="text"
                    name="nome"
                    placeholder="Nome para identificar o link"
                    value={formLink.nome}
                    onChange={onChangeLink}
                    disabled={loading.link}
                    readOnly={loading.link}
                />
                <Erro text={errors.nomeLink} />
              </Form.Field>
              <Responsive minWidth={768}>
                <Popup
                  trigger={
                    <Button
                      onClick={adicionarLink}
                      className="btn-ui-1"
                      size="medium"
                      loading={loading.link}
                      disabled={loading.link}
                    >
                      Adicionar
                    </Button>
                  }
                  content="Adicionar Link"
                  position="top center"
                />
              </Responsive>
              <Responsive maxWidth={767}>
                <Popup
                  trigger={
                    <Button
                      onClick={adicionarLink}
                      className="btn-ui-1"
                      size="small"
                      icon="plus"
                      loading={loading.link}
                      disabled={loading.link}
                    />
                  }
                  content="Adicionar Link"
                  size="small"
                  position='top center'
                />
              </Responsive>
            </Form.Group>
          </Form>
          {pergunta.links.length > 0 && pergunta.links.map(link => (
            <Link
              key={Math.random()}
              link={link}
              pergunta={pergunta}
              submitExcluir={excluirLink}
            />
          ))}
          <Divider />
          <div style={{ textAlign: "right" }}>
            {!!errors.titulo ? (
              <Erro text={errors.titulo} />
            ) : getFooterMsg()}
          </div>
        </div>
      </Segment>
    </div>
  );

  const getRemoveDialog = () => (
    <Segment>
      <Form>
        <div>
          <p><strong>{numeroPergunta}ª&nbsp; Pergunta / Pauta</strong></p>
          <div style={{ marginBottom: "20px" }}>
            <h3>{pergunta.titulo}</h3>
            <Divider />
            <h3>Deseja excluir essa pergunta / pauta? Essa ação não poderá ser desfeita.</h3>
          </div>
          <Form.Group widths={2}>
            <Form.Field>
              <Button as="a" size="large" fluid className="btn-ui-1" onClick={confirmarExclusao}><Icon name="trash alternate outline" />Excluir</Button>
            </Form.Field>
            <Form.Field>
              <Button as="a" size="large" fluid basic color="violet" onClick={cancelar}>Cancelar</Button>
            </Form.Field>
          </Form.Group>
        </div>
      </Form>
    </Segment>
  );

  return isRemove ? getRemoveDialog() : getContent();
};

export default Pergunta;
