import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = unidade => ({
  type: "CREATE_UNIDADE_SUCCESS",
  unidade
});

const addUnidadeCondominio = unidade => ({
  type: "ADD_UNIDADE_CONDOMINIO",
  unidade
});

const findSuccess = dados => ({
  type: "FIND_UNIDADES_SUCCESS",
  dados
});

const findByIdSuccess = unidade => ({
  type: "FIND_BY_ID_UNIDADE_SUCCESS",
  unidade
});

const findByIdAndUpdateSuccess = unidade => ({
  type: "UPDATE_UNIDADE_SUCCESS",
  unidade
});

const reenviarEmailHashSuccess = unidade => ({
  type: "REENVIAR_EMAIL_HASH_UNIDADE_SUCCESS",
  unidade
});

const validarQuantidadeSuccess = ({ habilitado }) => ({
  type: 'VALIDAR_QUANTIDADE_UNIDADE_SUCCESS',
  habilitado
});

const addError = errors => ({
  type: "ADD_UNIDADE_ERROR",
  errors
});

const gerarNovoHashUnidadeSuccess = unidade => ({
  type: "GERAR_NOVO_HASH_UNIDADE_SUCCESS",
  unidade
});

export const create = dados => async dispatch => {
  return api.unidade
    .create(dados)
    .then(data => {
      dispatch(addUnidadeCondominio(data));
      return dispatch(createSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findById = id => async dispatch => {
  return api.unidade
    .findById(id)
    .then(data => {
      return dispatch(findByIdSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const find = condominio => async dispatch => {
  return api.unidade
    .find(condominio)
    .then(data => {
      return dispatch(findSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.unidade
    .findByIdAndUpdate(id, data)
    .then(data => {
      return dispatch(findByIdAndUpdateSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const reenviarEmailHash = (unidadeId) => async dispatch => {
  return api.unidade
    .reenviarEmailHash(unidadeId)
    .then(data => {
      dispatch(addUnidadeCondominio(data));
      return dispatch(reenviarEmailHashSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByIdAndBloquearVotacao = (id) => async dispatch => {
  return api.unidade.findByIdAndBloquearVotacao(id).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler(error)));
  });
};

export const validarQuantidade = id => async dispatch => {
  return api.unidade.validarQuantidade(id).then(data => {
    return dispatch(validarQuantidadeSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler(error)));
  });
};

export const gerarNovoHashUnidade = oldHash => async dispatch => {
  return api.unidade.gerarNovoHashUnidade(oldHash)
    .then(data => {
      return dispatch(gerarNovoHashUnidadeSuccess(data));
    })
    .catch(error => {
      console.log(error);
      const err = errorHandler.getError(error);
      console.log(err);
      dispatch(addError(err));
    });
};
