import { create } from "apisauce";

const getUrlFormated = url => {
  if (url.indexOf("http:") === 0 || url.indexOf("https:") === 0) {
    return url;
  }
  return window.location.protocol === "https:"
    ? `https://${url}`
    : `http://${url}`;
};

const api = create({
  baseURL: getUrlFormated(process.env.REACT_APP_API_URL)
});

const apiIntegracao = create({
  baseURL: getUrlFormated(process.env.REACT_APP_API_INTEGRACAO_URL)
});

const apiViaCep = create({
  baseURL: "https://viacep.com.br/ws/"
});

api.addAsyncRequestTransform(request => async () => {
  const token = await localStorage[
    process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN
  ];
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
});

api.addResponseTransform(response => {
  if (response.status === 401) {
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN);
    window.location.reload();
    localStorage.tokenExpired = "Sua sessão expirou";
  }
  if (!response.ok) {
    throw response;
  }
});

apiIntegracao.addAsyncRequestTransform(request => async () => {
  const token = await localStorage[
    process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN
  ];
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
});

apiIntegracao.addResponseTransform(response => {
  if (response.status === 401) {
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN);
    window.location.reload();
    localStorage.tokenExpired = "Sua sessão expirou";
  }
  if (!response.ok) {
    throw response;
  }
});

export default {
  usuario: {
    cadastro: data =>
      api.post(`/auth/cadastro`, { ...data }).then(res => res.data),
    login: (email, senha, captchaToken) =>
      api
        .post(`/auth/login`, { email, senha, captchaToken, web: true })
        .then(res => res.data),
    confirmar: token =>
      api.post(`/confirmacao`, { token }).then(res => res.data.user),
    getUsuario: () => api.get(`/usuario/`).then(res => res.data.usuario),
    enviarEmailRecuperacaoSenha: email =>
      api.post(`/auth/forgot_password`, { email }).then(res => res.data),
    confirmarCadastro: token =>
      api.post(`/auth/confirm_account`, { token }).then(res => res.data),
    confirmarTokenRecuperacaoSenha: token =>
      api
        .post(`/auth/confirm_token_reset_password`, { token })
        .then(res => res.data),
    recuperarSenha: data =>
      api.post(`/auth/reset_password`, { ...data }).then(res => res.data),
    alterarSenha: usuario =>
      api.put(`/usuario/alterarSenha`, { ...usuario }).then(res => res.data),
    alterarEmail: usuario =>
      api.put(`/usuario/alterarEmail`, { ...usuario }).then(res => res.data),
    alterarDadosPessoais: usuario =>
      api.put(`/usuario/alterarDadosPessoais`, usuario).then(res => res.data),
    confirmarTrocaEmail: token =>
      api
        .post(`/auth/confirm_token_update_email`, { token })
        .then(res => res.data),
    reverterTrocaEmail: token =>
      api
        .post(`/auth/confirm_token_revert_email`, { token })
        .then(res => res.data),
    reenviarEmailConfirmacaoCadastro: () =>
      api.put(`/usuario/reenviar_email_confirmacao_cadastro`).then(res => res.data),
    findIntegracoes: () => api.get('/usuario/integracao').then(res => res.data),
    salvarIntegracao: data => api.post('/usuario/integracao/salvar', data).then(res => res.data),
    desativarIntegracao: data =>
      api.put('/usuario/integracao/desativar', data).then(res => res.data),
    updateLogotipo: async data => api.post(
      `/usuario/logotipo`, data,
      { headers: { "Content-Type": "multipart/form-data" } }
    ).then(res => res.data),
  },
  sistema: {
    enviarEmailContato: dados =>
      api.post(`/email/contato`, { ...dados }).then(res => res.data)
  },
  condominio: {
    create: data => api.post(`/condominio`, data).then(res => res.data),
    find: () => api.get(`/condominio`).then(res => res.data),
    findById: id => api.get(`/condominio/${id}`).then(res => res.data),
    findByIdAndUpdate: (id, data) => api.put(`/condominio/${id}`, data).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/condominio/${id}`).then(res => res.data),
    validarQuantidade: () => api.get('/condominio/validarQuantidade').then(res => res.data),
    findSimpleById: id => api.get(`/condominio/simple/${id}`).then(res => res.data),
    findForEdit: id => api.get(`/condominio/forEdit/${id}`).then(res => res.data),
    findAll: () => api.get('/condominio/findAll').then(res => res.data),
  },
  unidade: {
    create: data => api.post(`/unidade`, data).then(res => res.data),
    find: condominio => api.get(`/unidade/byCondominioId/${condominio}`).then(res => res.data),
    findById: id => api.get(`/unidade/${id}`).then(res => res.data),
    findByIdAndUpdate: (id, data) => api.put(`/unidade/${id}`, data).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/unidade/${id}`).then(res => res.data),
    reenviarEmailHash: (unidadeId) => api.post(`/unidade/reenviarEmailHash`, { unidadeId }).then(res => res.data),
    findByIdAndBloquearVotacao: (id) =>
      api.put(`/unidade/toggleBloquearVotacao/${id}`).then(res => res.data),
    validarQuantidade: id =>
      api.get(`/unidade/validarQuantidade/${id}`).then(res => res.data),
    enviarEmailUnidades: data =>
      api.post(`/unidade/enviarEmailUnidades`, data).then(res => res.data),
    sincronizar: condominio =>
      api.post(`/unidade/sincronizar/byCondominioId/${condominio}`).then(res => res.data),
    checkSincronizacao: condominio =>
      api.get(`unidade/sincronizar/byCondominioId/${condominio}/status`).then(res => res.data),
    relatorioUnidades: (condominioId, data) =>
      api.post(`/unidade/byCondominioId/${condominioId}/relatorio`, data).then(res => res.data),
    comboFindById: id => api.get(`/unidade/comboByCondominioId/${id}`).then(res => res.data),
    gerarNovoHashUnidade: data => api.post('/unidade/gerarNovoHash', data).then(res=> res.data),
    relatorioLeituraEmail: condominioId =>
      api.get(`/unidade/byCondominioId/${condominioId}/relatorioLeituraEmail`).then(res => res.data),
    relatorioListagemUnidades: condominioId =>
      api.get(`/unidade/byCondominioId/${condominioId}/relatorioListagemUnidades`).then(res => res.data),
    getBlocos: condominioId =>
      api.get(`/unidade/blocos/${condominioId}`).then(res => res.data),
  },
  responsavel: {
    create: data => api.post(`/responsavel`, data).then(res => res.data),
    find: condominio =>
      api
        .get(`/responsavel/byCondominioId/${condominio}`)
        .then(res => res.data),
    findById: id => api.get(`/responsavel/${id}`).then(res => res.data),
    findByDocumento: documento =>
      api.get(`/responsavel/byDocumento/${documento}`).then(res => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/responsavel/${id}`, data).then(res => res.data),
    findByIdAndRemove: id =>
      api.delete(`/responsavel/${id}`).then(res => res.data),
  },
  votacao: {
    create: async data => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.post(`/votacao/`, data, config);
      return res.data;
    },
    find: condominio =>
      api.get(`/votacao/web/byCondominioId/${condominio}`).then(res => res.data),
    findById: id => api.get(`/votacao/web/${id}`).then(res => res.data),
    findDocumentoByIdAndUpdate: async (id, data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.put(`/votacao/documento/${id}`, data, config);
      return res.data;
    },
    findByIdAndUpdate: (id, data) =>
      api.put(`/votacao/${id}`, data).then(res => res.data),
    findByIdAndUpdateExtra: (id, data) =>
      api.put(`/votacao/extra/${id}`, data).then(res => res.data),
    iniciarVotacao: (id, { votacaoIniciada, condominio }) =>
      api.put(`/votacao/web/iniciarVotacao/${id}`, { votacaoIniciada, condominio }).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/votacao/${id}`).then(res => res.data),
    finalizarVotacao: (id, condominio) =>
      api.put(`/votacao/web/finalizarVotacao/${id}`, { condominioId: condominio._id }).then(res => res.data),
    gerarRelatorioPresenca: (id, condominio, showDocumentPresenceList, showDeviceInfo, showEmail) =>
      api.post(`/votacao/relatorio/listaPresenca/${id}`,
               { condominioId: condominio._id, showDocumentPresenceList, showDeviceInfo, showEmail }),
    gerarRelatorioResultadoVotacao: (id, condominio, showVoteList) =>
      api.post(`/votacao/relatorio/resultadoVotacao/${id}`, { condominioId: condominio._id, showVoteList }),
    gerarRelatorioChatVotacao: (id, condominio) =>
      api.post(`/votacao/relatorio/chatVotacao/${id}`, { condominioId: condominio._id }),
    gerarRelatorioAtaVotacao: (id, condominio, showVoteList) =>
      api.post(`/votacao/relatorio/ataVotacao/${id}`, { condominioId: condominio._id, showVoteList }),
    toggleExibirVotacao: (id) =>
      api.put(`/votacao/toggleExibirVotacao/${id}`).then(res => res.data),
    findAll: condominio =>
      api.get(`/votacao/web/findAllByCondominio/${condominio}`).then(res => res.data),
    findForEdit: id =>
      api.get(`/votacao/web/findForEdit/${id}`).then(res => res.data),
    checkPossuiVoto: id => api.get(`/votacao/web/checkPossuiVoto/${id}`).then(res => res.data),
    atualizarDocumento: async (id, data, options) =>
      api.put(
        `/votacao/atualizarDocumento/${id}`, data,
        { headers: { "Content-Type": "multipart/form-data" }, ...(options || {}) }
      ).then(res => res.data),
    updateSobre: (id, data) => api.put(`/votacao/web/sobre/${id}`, data,).then(res => res.data),
    updateSobreExtras: (id, data) => api.put(`/votacao/web/sobreExtras/${id}`, data,).then(res => res.data),
    iniciarVotacaoV2: (id, { votacaoIniciada, condominio }) =>
      api.put(`/votacao/web/iniciarVotacaoV2/${id}`, { votacaoIniciada, condominio }).then(res => res.data),
    finalizarVotacaoV2: (id, condominio) =>
      api.put(`/votacao/web/finalizarVotacaoV2/${id}`, { condominioId: condominio }).then(res => res.data),
  },
  pergunta: {
    create: data => api.post(`/pergunta`, data).then(res => res.data),
    findById: id => api.get(`/pergunta/${id}`).then(res => res.data),
    findByIdAndUpdate: (id, data) => api.put(`/pergunta/${id}`, data).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/pergunta/${id}`).then(res => res.data),
    addLink: (id, data) => api.post(`/pergunta/${id}/link`, data).then(res => res.data),
    removeLink: (idPergunta, idLink) => api.delete(`/pergunta/${idPergunta}/link/${idLink}`).then(res => res.data),
    findForEdit: id => api.get(`/pergunta/forEdit/${id}`).then(res => res.data),
    findForStats: (condominioId, id) => api.get(`/pergunta/forStats/${condominioId}/${id}`).then(res => res.data),
    findByIdAndUpdateBlocos: (id, data) => api.put(`/pergunta/${id}/blocos`, data).then(res => res.data),
  },
  resposta: {
    create: data => api.post(`/resposta`, data).then(res => res.data),
    findById: id => api.get(`/resposta/${id}`).then(res => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/resposta/${id}`, data).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/resposta/${id}`).then(res => res.data)
  },
  documento: {
    create: async data => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.post(`/documento`, data, config);
      return res.data;
    },
    findById: id => api.get(`/documento/${id}`).then(res => res.data),
    findByIdAndUpdate: async (id, data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.put(`/documento/${id}`, data, config);
      return res.data;
    },
    findByIdAndRemove: id =>
      api.delete(`/documento/${id}`).then(res => res.data)
  },
  pagamento: {
    create: dados =>
      api.post(`/pagamento`, dados).then(res => res.data),
    find: condominioId =>
      api.get(`/pagamento/${condominioId}`).then(res => res.data),
    planos: () => api.get('/pagamento/planos').then(res => res.data),
    updateStatus: hash => api.get(`/pagamento/status/${hash}`).then(res => res.data),
  },
  notificacao: {
    create: data => api.post(`/notificacao`, data).then(res => res.data),
    find: condominio => api.get(`/notificacao/byCondominioId/${condominio}`).then(res => res.data),
    findById: id => api.get(`/notificacao/${id}`).then(res => res.data),
    findByIdAndUpdate: (id, data) => api.put(`/notificacao/${id}`, data).then(res => res.data),
    findByIdAndRemove: id => api.delete(`/notificacao/${id}`).then(res => res.data),
    findByIdAndSend: id => api.post(`/notificacao/${id}/enviar`).then(res => res.data),
    relatorioGeral: condominioId =>
      api.get(`/notificacao/byCondominioId/${condominioId}/relatorio`).then(res => res.data),
    relatorio: (condominioId, id) =>
      api.get(`/notificacao/byCondominioId/${condominioId}/relatorio/${id}`).then(res => res.data),
  },
  cupom: {
    check: codigo => api.post('/cupom/check', { codigo }).then(res => res.data),
  },
  global: {
    findEnderecoByCep: cep => apiViaCep.get(`${cep}/json/`).then(res => res)
  },
  importacao: {
    importar: async (data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await apiIntegracao.post(`/import`, data, config);
      return res.data;
    },
  }
};
