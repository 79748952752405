import React, {useState} from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Transition, Button, List, Image, Responsive, Icon, Modal, Popup } from "semantic-ui-react";
import 'moment/locale/pt-br';
moment.locale('pt-BR');

const Unidade = ({ unidade, animation, bloquearHandle }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModal = () => setModalOpen(s => !s);

  const botaoBloquear = (unidade, positiveBtnAction, small = false) => {
    let action = 'Bloquear';
    let iconLock = <Icon name="lock"/>;

    if (unidade && unidade.bloqueadoVotacao) {
      action = 'Desbloquear';
      iconLock = <Icon name="lock open"/>;
    }

    return (
      <Modal
        trigger={
          <Popup
            content={`Clique aqui para ${action.toLocaleLowerCase()} temporariamente o acesso dessa unidade às votações.`}
            position='top center'
            size="large"
            trigger={
              <Button
                className="btn-ui-2"
                size="large"
                icon={small}
                onClick={handleModal}
              >
                {small ? iconLock : action}
              </Button>
            }
          />
        }
        header='Confirmação'
        open={modalOpen}
        onClose={handleModal}
        content={`Deseja ${action.toLocaleLowerCase()} o acesso da unidade selecionada às votações?`}
        actions={[
          {
            key: 'close',
            content: 'Não',
            className: 'btn-ui-1 btn-ui-1-basic'
          }, {
            key: 'done',
            content: 'Sim',
            className: 'btn-ui-1',
            unidade,
            onClick: positiveBtnAction
          }
        ]}
      />
    );
  };

  return (
    <Transition duration={!isNaN(animation) ? animation : 500} animation="fade up" transitionOnMount={true}>
      <List.Item className="animated-list-item">
        <Image src="/images/illustrations/unidade.svg" size="mini"/>
        <List.Content className="animated-list-content">
          <List.Header style={{ wordWrap: "break-word", fontSize: "1.2em", marginBottom: "5px", lineHeight: "1.2em" }}>
            {`${unidade.bloco} ${unidade.numero}`.trim()}
            {!!unidade.responsavel &&
              <span style={{ fontWeight: 100 }}>&nbsp;&nbsp;•&nbsp;&nbsp;{unidade.responsavel.nome}</span>
            }
            {unidade.bloqueadoVotacao && <Icon name="ban" color="red" style={{ marginLeft: "5px" }}/>}
          </List.Header>
          <List.Description style={{ fontSize: "1.1em" }}>
            Criado {moment(unidade.createdAt).fromNow()}
          </List.Description>
          <Responsive minWidth={768}>
            <div className="btn-right">
              <Button as={Link} to={`/condominios/${unidade.condominio}/unidades/editar/${unidade._id}`} className="btn-ui-1"
                      size="large">Editar</Button>
              {botaoBloquear(unidade, bloquearHandle)}
            </div>
          </Responsive>
          <Responsive maxWidth={767}>
            <div className="btn-right">
              <Button as={Link} to={`/condominios/${unidade.condominio}/unidades/editar/${unidade._id}`} className="btn-ui-1"
                      icon size="large"><Icon name="pencil"/></Button>
              {botaoBloquear(unidade, bloquearHandle, true)}
            </div>
          </Responsive>
        </List.Content>
      </List.Item>
    </Transition>
  );
};

export default Unidade;
