const initState = {
  votacao: {
    titulo: "",
    local: "",
    dataInicioReuniao: "",
    dataFinalReuniao: "",
    horarioReuniao: "",
    horarioFinalReuniao: "",
    urlTransmissao: "",
    votacaoIniciada: false,
    votacaoFinalizada: true,
    documentoConvocacao: {
      nome: "",
      url: ""
    },
    documentoAta: {
      nome: "",
      url: ""
    },
    perguntas: []
  },
  listaPresenca: [],
  votacoes: [],
  errors: {}
};

const votacao = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_VOTACOES_SUCCESS':
      return {
        ...initState,
        votacoes: action.dados.votacoes,
      };
    case 'CREATE_VOTACAO_SUCCESS':
      return {
        ...initState,
        votacoes: [action.votacao, ...state.votacoes],
      };
    case 'CREATE_PERGUNTA_SUCCESS':
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: [...state.votacao.perguntas, action.pergunta]
        },
      };
    case 'UPDATE_PERGUNTA_SUCCESS':
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === action.pergunta._id) ? action.pergunta : p
          )
        },
      };
    case 'REMOVE_PERGUNTA_SUCCESS':
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.filter(p => (p._id !== action.pergunta._id))
        },
      };
    case 'CREATE_RESPOSTA_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.resposta.pergunta);
      pergunta.respostas = [...pergunta.respostas, action.resposta];
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'REMOVE_RESPOSTA_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.resposta.pergunta);
      const respostas = pergunta.respostas.filter(r => r._id !== action.resposta._id);
      pergunta.respostas = respostas;
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'UPDATE_RESPOSTA_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.resposta.pergunta);
      const respostas = pergunta.respostas.map(r =>
        (r._id === action.resposta._id) ? action.resposta : r);
      pergunta.respostas = respostas;
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'CREATE_DOCUMENTO_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.documento.pergunta);
      pergunta.documentos = [...pergunta.documentos, action.documento];
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'REMOVE_DOCUMENTO_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.documento.pergunta);
      const documentos = pergunta.documentos.filter(d => d._id !== action.documento._id);
      pergunta.documentos = documentos;
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'UPDATE_DOCUMENTO_SUCCESS': {
      const pergunta = state.votacao.perguntas.find(p => p._id === action.documento.pergunta);
      const documentos = pergunta.documentos.map(d =>
        (d._id === action.documento._id) ? action.documento : d);
      pergunta.documentos = documentos;
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(p =>
            (p._id === pergunta._id) ? pergunta : p
          )
        },
      };
    }
    case 'FIND_BY_ID_VOTACAO_SUCCESS':
      return {
        ...initState,
        votacao: { ...state.votacao, ...action.votacao },
        listaPresenca: action.listaPresenca
      };
    case 'UPDATE_VOTACAO_SUCCESS':
      return {
        ...initState,
        votacao: action.votacao,
        listaPresenca: action.listaPresenca,
        votacoes: state.votacoes.map(votacao => {
          if (votacao._id === action.votacao._id)
            return action.votacao;
          return votacao;
        })
      };
    case 'UPDATE_DOCUMENTO_CONVOCACAO_VOTACAO_SUCCESS':
      return {
        ...initState,
        votacao: action.votacao
      };
    case 'ADD_LINK_PERGUNTA_SUCCESS':
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(it => {
            if (it._id === action.link.pergunta) {
              it.links.push(action.link);
            }
            return it;
          })
        }
      };
    case 'REMOVE_LINK_PERGUNTA_SUCCESS':
      return {
        ...state,
        votacao: {
          ...state.votacao,
          perguntas: state.votacao.perguntas.map(it => {
            if (it._id === action.link.pergunta) {
              it.links = it.links.filter(link => link._id !== action.link._id);
            }
            return it;
          })
        }
      };
    case 'ADD_VOTACAO_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    case 'TOGGLE_EXIBIR_VOTACAO_SUCCESS':
      return {
        ...state,
        votacoes: state.votacoes.map(votacao => {
          if (votacao._id === action.payload.votacao._id) {
            return action.payload.votacao;
          }
          return votacao;
        })
      };
    default:
      return state;
  }
};

export default votacao;
