import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import MenuHorizontal from "../../layouts/MenuHorizontal";
import { Input, Button, Form, Transition, Icon, Container } from "semantic-ui-react";
import { findById, findByIdAndUpdate } from "../../../actions/responsavel";
import * as FormValidator from "../../../utils/formValidator";
import Erro from '../../layouts/mensagens/Erro';
import MenuVertical from '../../layouts/MenuVertical';
import { Redirect, Link } from 'react-router-dom';
import InputMask from "react-text-mask";
import { formatDocumento } from '../../../utils/formatador';
import api from '../../../api';

class EditarResponsavel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condominio: {},
      responsavel: props.responsavel,
      errors: {},
      sucesso: false,
      loading: true
    };
  }

  componentDidMount = async () => {
    try {
      await this.props.findById(this.props.match.params.id);
      const condominio = await api.condominio.findSimpleById(this.props.match.params.condominioId);
      this.setState({
        condominio,
        errors: this.props.errors,
        loading: false,
        responsavel: this.props.responsavel,
      });
    } catch (e) {
      this.setState({
        loading: false,
        errors: {
          ...this.props.errors,
          ...e.data.errors,
        },
      });
    }
  }

  onChange = e => {
    this.setState({
      responsavel: { ...this.state.responsavel, [e.target.name]: e.target.value },
      errors: {}, sucesso: false
    });
  }

  validate = responsavel => {
    const errors = {};

    if (FormValidator.isVazio(responsavel.nome)) {
      errors.nome = 'Nome é obrigatório';
    }

    if (FormValidator.isVazio(responsavel.email)) {
      errors.email = 'Email é obrigatório';
    }

    if (!FormValidator.isVazio(responsavel.email) && !FormValidator.isEmail(responsavel.email)) {
      errors.email = 'Email inválido';
    }

    if (!FormValidator.isVazio(responsavel.celular.replace(/\D/g, '')) && !FormValidator.isNumeroCelular(responsavel.celular)) {
      errors.celular = 'Número de celular inválido';
    }

    return errors;
  }

  onSubmitEditar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.responsavel);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(this.state.responsavel._id, this.state.responsavel);
      this.setState({
        loading: false,
        sucesso: (Object.keys(this.props.errors).length === 0),
        errors: this.props.errors
      });
    }
  }

  render() {
    const { responsavel, errors, loading, sucesso, condominio } = this.state;
    return (
      <div>
        {!!errors.global && <Redirect to="/" />}
        <MenuHorizontal />
        <div className="corpo-dashboard">
          <MenuVertical condominio={condominio} loading={loading} />
          <Container style={{ padding: "20px" }} fluid>
            <Erro text={errors.error} tipo="block" />
            <Transition
              duration={{ show: 1200, hide: 0 }}
              animation="fade up"
              transitionOnMount={true}
              visible={sucesso}
            >
              <div style={{ textAlign: 'center', marginTop: 40 }}>
                <Icon name="check circle outline" size="massive" color="green" />
                <br />
                <h3>Proprietário atualizado com sucesso</h3>
                <Link to={`/condominios/${condominio._id}/responsaveis`} size="small">Voltar</Link>
              </div>
            </Transition>
            {!sucesso && (
              <Form
                onSubmit={this.onSubmitEditar}
                loading={loading}
                autoComplete="off"
                size="large"
              >
                <div className="ui-crud-form">
                  <h3>Editar Proprietário</h3>
                  <Form.Group widths={2}>
                    <Form.Field width={6}>
                      <label htmlFor="documento">Documento do Proprietário ou Procurador</label>
                      <Input
                        name="documento"
                        type="text"
                        value={formatDocumento(responsavel.documento)}
                        placeholder="CPF ou CNPJ"
                        loading={loading}
                        disabled={true}
                      />
                    </Form.Field>
                    <Form.Field error={!!errors.nome} width={10}>
                      <label htmlFor="nome">Nome Completo</label>
                      <Input
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        onChange={this.onChange}
                        value={responsavel.nome}
                        loading={loading}
                      />
                      <Erro text={errors.nome} />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.celular} width={6}>
                      <label htmlFor="celular">Celular</label>
                      <InputMask
                        type="text"
                        id="celular"
                        name="celular"
                        placeholder="(ddd) número"
                        mask={[
                          '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
                        ]}
                        guide={true}
                        onChange={this.onChange}
                        value={responsavel.celular}
                      />
                      <Erro text={errors.celular} />
                    </Form.Field>
                    <Form.Field error={!!errors.email} width={10}>
                      <label htmlFor="email">Email</label>
                      <Input
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={this.onChange}
                        value={responsavel.email}
                      />
                      <Erro text={errors.email} />
                    </Form.Field>
                  </Form.Group>
                  <div style={styles.divBtn}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      className="btn-ui-1"
                      size="large"
                    >
                      Salvar Proprietário
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

const styles = {
  divBtn: {
    textAlign: "center",
    marginTop: "20px"
  }
};

const mapStateToProps = (state) => {
  return {
    responsavel: state.responsavel.responsavel,
    errors: state.responsavel.errors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    findById,
    findByIdAndUpdate,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarResponsavel);
