import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = responsavel => ({
  type: 'CREATE_RESPONSAVEL_SUCCESS',
  responsavel
});

const findSuccess = (dados) => ({
  type: 'FIND_RESPONSAVEIS_SUCCESS',
  dados,
})

const findByIdSuccess = responsavel => ({
  type: 'FIND_BY_ID_RESPONSAVEL_SUCCESS',
  responsavel
})

const findByDocumentoSuccess = responsavel => ({
  type: 'FIND_BY_DOCUMENTO_RESPONSAVEL_SUCCESS',
  responsavel
})

const findByIdAndUpdateSuccess = responsavel => ({
  type: 'UPDATE_RESPONSAVEL_SUCCESS',
  responsavel
})

const resetStateSuccess = () => ({
  type: 'RESET_RESPONSAVEL_STATE'
})

const addError = errors => ({
  type: 'ADD_RESPONSAVEL_ERROR',
  errors
})

const addFindByDocumentoError = errors => ({
  type: 'ADD_FIND_BY_DOCUMENTO_RESPONSAVEL_ERROR',
  errors
})

export const create = (dados) => async dispatch => {
  return api.responsavel.create(dados).then(data => {
    return dispatch(createSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findById = id => async dispatch => {
  return api.responsavel.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const find = (condominio) => async dispatch => {
  return api.responsavel.find(condominio).then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.responsavel.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByDocumento = (documento) => async dispatch => {
  return api.responsavel.findByDocumento(documento).then(data => {
    return dispatch(findByDocumentoSuccess(data));
  }).catch(error => {
    dispatch(addFindByDocumentoError(errorHandler.getError(error)));
  })
}

export const resetState = () => dispatch => {
  dispatch(resetStateSuccess());
}
