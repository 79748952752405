import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Transition, Card, Button, Icon, Checkbox, Popup, Dimmer, Loader } from "semantic-ui-react";
import { toggleExibirVotacao } from "../../../actions/votacao";
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');

export default function Votacao({ votacao, atualizarLista }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function toggleVotacao() {
    setLoading(true);
    await dispatch(toggleExibirVotacao(votacao._id));
    await atualizarLista();
    setLoading(false);
  }

  return (
    <Transition duration={500} animation="fade up" transitionOnMount={true}>
      <Card style={styles.card}>
        <Card.Content>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          <Card.Header>{votacao.titulo}</Card.Header>
          <Card.Meta>
            <span>Criado {moment(votacao.createdAt).fromNow()}</span>
          </Card.Meta>
          <Card.Description style={{ fontSize: "1.1em" }}>
            <br />
            <p style={styles.singleLine}><Icon name="map marker alternate" />{votacao.local}</p>
            <p><Icon name="clock outline" />{votacao.horarioReuniao} - {votacao.horarioFinalReuniao || '00:00'}</p>
            <p><Icon name="calendar alternate outline" />{votacao.dataInicioReuniao} - {votacao.dataFinalReuniao}</p>
            {votacao.urlTransmissao ? (
              <p>
                <Icon name="tv" />
                <a href={votacao.urlTransmissao} target="_blank" rel="noopener noreferrer">
                  Transmissão
                </a>
              </p>
            ) : <></>}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button as={Link} to={`/condominios/${votacao.condominio}/votacoes/editar/${votacao._id}/sobre`} className="btn-ui-1" fluid size="large">Editar</Button>
        </Card.Content>
        <Card.Content extra>
          <div style={styles.activateContainer}>
            <label htmlFor="ativo">Ocultar/Exibir Votação</label>
            <Popup
              trigger={
                <Checkbox
                  toggle
                  name="ativo"
                  onChange={() => toggleVotacao()}
                  checked={votacao.exibirNoApp} style={{marginTop: "10px"}}
                />
              }
              content='Clique aqui para Ocultar/Exibir a votação no Aplicativo do condômino.'
              size='large'
              position='top center'
            />
          </div>
        </Card.Content>
      </Card>
    </Transition>
  )
};

const styles = {
  singleLine: {
    height: "1.1em",
    lineHeight: "1.1em",
    overflow: "hidden"
  },
  card: {
    width: "100%",
    paddingTop: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  activateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
  }
};
