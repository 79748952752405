import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Icon,
  Form,
  Image,
  Transition,
  Segment,
  Menu,
  Input,
  Button,
  Container,
  Message,
  List,
  Popup,
} from 'semantic-ui-react';
import * as FormValidator from '../../../utils/formValidator';
import InputMask from 'react-text-mask';
import Erro from '../../layouts/mensagens/Erro';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import {
  reenviarEmailConfirmacaoCadastro,
  alterarDadosPessoais,
  alterarEmail,
  alterarSenha,
  salvarIntegracao,
  desativarIntegracao,
  salvarNovoLogotipo,
} from '../../../actions/usuario';
import moment from 'moment';
import Integracao from './Integracao';
import api from '../../../api';
import { SUPERLOGICA } from '../../../models/enums/tipoIntegracao';

const Perfil = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);

  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);

  const [sucessoEmail, setSucessoEmail] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({ show: false, sucesso: true });
  const [logo, setLogo] = useState(null);

  const [errors, setErrors] = useState({});

  const [activeItem, setActiveItem] = useState('Perfil');

  const [form, setForm] = useState({
    usuario: { ...user },
    senha: {
      senhaAtual: '',
      novaSenha: '',
      novaSenha2: ''
    },
    imagem_preview_display: 'none',
  });

  const [superlogicaForm, setSuperlogicaForm] = useState({
    ativo: false,
    pendente: false,
    loading: false,
    dados: {
      appToken: '',
      accessToken: '',
    },
    errors: {}
  });

  useEffect(
    () => {
      setSuperlogicaForm(p => ({ ...p, loading: true }));
      api.usuario.findIntegracoes().then(res => {
        let superlogica;

        if (res && !!res.integracoes && res.integracoes.length > 0) {
          superlogica = res.integracoes.find(it => it.tipo === SUPERLOGICA.value);
        }

        if (superlogica && superlogica.ativo) {
          setSuperlogicaForm({
            ativo: true,
            pendente: false,
            loading: false,
            dados: superlogica.dados,
            errors: {}
          });
        } else {
          setSuperlogicaForm(p => ({ ...p, loading: false }));
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeUsuarioState = (name, value) => {
    setErrors({});
    setForm(state => ({
      ...state,
      usuario: {
        ...state.usuario,
        [name]: value,
      },
    }));
  };

  const changeSenhaState = (name, value) => {
    setErrors({});
    setForm(state => ({
      ...state,
      senha: {
        ...state.senha,
        [name]: value,
      },
    }));
  };

  const onChangeUsuario = e => changeUsuarioState(e.target.name, e.target.value);
  const onChangeSelect = (_, { name, value }) => changeUsuarioState(name, value);
  const onChangeSenha = e => changeSenhaState(e.target.name, e.target.value);

  const getGeneros = () => [
    { key: 'masculino', value: 'masculino', text: 'Masculino' },
    { key: 'feminino', value: 'feminino', text: 'Feminino' },
    { key: 'outro', value: 'outro', text: 'Outro' }
  ];

  const podeReenviarEmail = () => {
    let result = true;
    if (form.usuario.confirmacaoCadastro && form.usuario.confirmacaoCadastro.dataExpiracao) {
      const data = moment(form.usuario.confirmacaoCadastro.dataExpiracao);
      if (data) {
        result = moment.duration(data.add(-1, 'hour').diff(moment())).asMinutes() <= -10;
      }
    }
    return result;
  };

  const reenviarEmail = async () => {
    setLoadingEmail(true);
    try {
      const usuario = await dispatch(reenviarEmailConfirmacaoCadastro());
      setSucessoEmail(true);
      setLoadingEmail(false);
      setForm(state => ({ ...state, usuario }));
      setTimeout(() => setSucessoEmail(false), 5000);
    } catch (error) {
      setLoadingEmail(false);
    }
  };

  const handleItemClick = (_, { name }) => {
    setUpdateStatus({ show: false });
    setActiveItem(name);
  };

  const validate = () => {
    const err = {};

    const rxCel = /^\(\d{2}\)\s\d{4}\d{0,1}-\d{4}$/g;

    if (!FormValidator.isVazio(form.usuario.celular) && !rxCel.test(form.usuario.celular)) {
      err.celular = 'Telefone inválido';
    }

    return err;
  };

  const onSubmitDados = async () => {
    const validation = validate();
    if (Object.keys(validation).length > 0) {
      setErrors(validation);
    } else {
      setLoading(true);
      try {
        await dispatch(alterarDadosPessoais(form.usuario));
        setUpdateStatus({ show: true, sucesso: true });
      } catch(error) {
        setUpdateStatus({ show: true, sucesso: false });
      }
      setLoading(false);
    }
  };

  const onSubmitEmail = async () => {
    if (FormValidator.isVazio(form.usuario.email)) {
      setErrors({ email: 'Email obrigatório' });
    } else if (!FormValidator.isEmail(form.usuario.email)) {
      setErrors({ email: 'Email inválido' });
    } else {
      setLoading(true);
      try {
        await dispatch(alterarEmail({ email: form.usuario.email }));
        setUpdateStatus({ show: true, sucesso: true, message: 'Sucesso! Acesse o novo email para confirmá-lo.' });
      } catch (error) {
        setUpdateStatus({ show: true, sucesso: false, message: error.data.error });
      }
      setLoading(false);
    }
  };

  const onSubmitSenha = async () => {
    const err = {};

    if (FormValidator.isVazio(form.senha.senhaAtual)) {
      err.senhaAtual = 'Informe a senha atual';
    }

    if (FormValidator.isVazio(form.senha.novaSenha)) {
      err.novaSenha = 'Informe a nova senha';
    }

    if (FormValidator.isVazio(form.senha.novaSenha2)) {
      err.novaSenha2 = 'Confirme a nova senha';
    } else if (form.senha.novaSenha !== form.senha.novaSenha2) {
      err.novaSenha = 'Senhas não conferem';
      err.novaSenha2 = 'Senhas não conferem';
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
    } else {
      setLoading(true);
      try {
        await dispatch(alterarSenha(form.senha));
        setUpdateStatus({ show: true, sucesso: true });
      } catch (error) {
        setUpdateStatus({ show: true, sucesso: false, message: error.data.error });
      }
      setLoading(false);
    }
  };

  const closeMessage = () => setUpdateStatus({ show: false });

  const changeStateSuperlogica = async () => {
    if (superlogicaForm.ativo) {
      setSuperlogicaForm(p => ({ ...p, loading: true }));
      dispatch(desativarIntegracao({ tipo: SUPERLOGICA.value })).then(() => {
        setSuperlogicaForm({
          ativo: false,
          pendente: false,
          loading: false,
          dados: {
            appToken: '',
            accessToken: '',
          },
          errors: {}
        });
      });
    } else if (superlogicaForm.pendente) {
      const err = {};

      if (FormValidator.isVazio(superlogicaForm.dados.appToken)) {
        err.appToken = "App Token Obrigatório";
      }

      if (FormValidator.isVazio(superlogicaForm.dados.accessToken)) {
        err.accessToken = "Access Token Obrigatório";
      }

      if (Object.keys(err).length > 0) {
        setSuperlogicaForm(p => ({ ...p, loading: false, errors: err }));
      } else {
        setSuperlogicaForm(p => ({ ...p, loading: true }));

        dispatch(salvarIntegracao({
          tipo: SUPERLOGICA.value,
          dados: superlogicaForm.dados,
        })).then(() => {
          setSuperlogicaForm({
            ativo: true,
            pendente: false,
            loading: false,
            dados: superlogicaForm.dados,
            errors: {}
          });
        });
      }

    } else {
      setSuperlogicaForm(p => ({ ...p, pendente: true }));
    }
  };

  const cancelSuperlogica = () => {
    setSuperlogicaForm(p => ({ ...p, pendente: false }));
  };

  const changeDadosSuperlogica = (name, value) => setSuperlogicaForm(p => ({
    ...p,
    dados: {
      ...p.dados,
      [name]: value,
    },
    errors: {}
  }));

  const onChangeDadosSuperlogica = e => changeDadosSuperlogica(e.target.name, e.target.value);

  const onChangeLogo = ({ target: { files } }) => {
    if (files && files[0] && files[0].type.indexOf('image/') !== -1) {
      if (FormValidator.sizeLimit(files[0], 2)) {
        setLogo(files[0]);
        setErrors({});
      } else {
        setErrors({ logo: 'Permitido arquivos de até 2mb' });
      }
    }
  };

  const onSubmitLogo = async () => {
    if (logo) {
      setLoadingLogo(true);
      const formData = new FormData();
      formData.append('arquivo', logo);
      try {
        const logotipo = await api.usuario.updateLogotipo(formData);
        dispatch(salvarNovoLogotipo(logotipo));
        setForm(p => ({ ...p, usuario: { ...p.usuario, logotipo } }));
        setLoadingLogo(false);
        setLogo(null);
        setUpdateStatus({ show: true, sucesso: true, message: 'Logotipo atualizado com sucesso!' });
      } catch (e) {
        setErrors(e.data.errors);
      }
    } else {
      setErrors({ logo: 'Selecione um logotipo' });
    }
  };

  const existeLogo = () => !!form.usuario.logotipo && form.usuario.logotipo.url;
  const getLogoLabel = () => existeLogo() ? 'Alterar logotipo atual' : 'Selecionar logotipo';
  const getLogoLink = () => existeLogo() && <a href={form.usuario.logotipo.url} target="_blank" rel="noopener noreferrer">Ver logotipo atual</a>;

  const animationTime = { show: 1000, hide: 0 };

  return (
    <div>
      <MenuHorizontal />
      <div className="corpo-dashboard-mini">
        <MenuVertical page="perfil" />
        <Container fluid>
          <Segment padded='very' className="segment-perfil">
            <div className="div-perfil">
              {form.usuario.fotoPerfil ? (
                <Image avatar size="tiny" src={form.usuario.fotoPerfil} />
              ) : (
                <Icon name="user circle outline" size="huge" />
              )}
              <h3 style={{ fontWeight: 100 }}>Bem-vindo, {form.usuario.nome}</h3>
              <div className="status-conta">
                {form.usuario.ativo ? (
                  <p className="status-conta-1">
                    <Icon color='violet' name='check' /> Conta verificada
                  </p>
                ) : (
                  <p className="status-conta-2">
                    <span>
                      <Icon color='yellow' name='warning sign' />
                      Conta não verificada. Ative sua conta acessando o
                      <b>link</b> que foi enviado no email cadastrado.
                    </span>
                    <span style={{ display: 'inline-block', width: '100%', margin: '15px 0' }}>
                      Não recebeu o email?
                    </span>
                    <Button
                      className="btn-ui-1"
                      size="tiny"
                      primary
                      loading={loadingEmail}
                      onClick={reenviarEmail}
                      disabled={!podeReenviarEmail() || loadingEmail}
                    >
                      <Icon name="envelope" /> Reenviar Email
                    </Button>
                    {sucessoEmail && (
                      <span
                        style={{ display: 'inline-block', width: '100%', marginTop: 15 }}
                        className="msg-sucesso-inline"
                      >
                        Email enviado com sucesso!
                      </span>
                    )}
                    {!podeReenviarEmail() && (
                      <span style={{ display: 'inline-block', width: '100%', marginTop: 15 }}>
                        Aguarde alguns minutos para solicitar um novo reenvio
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
            <Menu pointing secondary color="violet" className="submenu2" stackable widths={3}>
              <Menu.Item name="Perfil" active={activeItem === 'Perfil'} onClick={handleItemClick}>
                <Icon name="user circle outline" /> Perfil
              </Menu.Item>
              <Menu.Item name="Conta" active={activeItem === 'Conta'} onClick={handleItemClick}>
                <Icon name="shield alternate" /> Conta
              </Menu.Item>
              <Menu.Item name="Integ" active={activeItem === 'Integ'} onClick={handleItemClick}>
                <Icon name="sitemap" /> Integrações
              </Menu.Item>
            </Menu>
          </Segment>
          <Segment padded="very" style={{ marginBottom: "120px" }}>
            <Transition
              animation="fade up"
              duration={1000}
              visible={updateStatus.show && updateStatus.sucesso}
            >
              <Message positive size="tiny" onDismiss={closeMessage}>
                <Icon name="check" /> {updateStatus.message || 'Dados alterados com sucesso!'}
              </Message>
            </Transition>
            <Transition
              animation="fade up"
              duration={1000}
              visible={updateStatus.show && !updateStatus.sucesso}
            >
              <Message negative size="tiny" onDismiss={closeMessage}>
                <Icon name='warning' /> {updateStatus.message || 'Erro ao atualizar os dados!'}
              </Message>
            </Transition>
            <Transition
              animation="fade down"
              duration={animationTime}
              visible={activeItem === 'Perfil'}
            >
              <div>
                <Form loading={loading}>
                  <h3>Dados pessoais</h3>
                  <Form.Field>
                    <label htmlFor="nome">Nome</label>
                    <Input
                      id="nome"
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      onChange={onChangeUsuario}
                      value={form.usuario.nome}
                    />
                  </Form.Field>
                  <Form.Group widths={"equal"}>
                    <Form.Field error={!!errors.genero} required>
                      <Form.Select
                        fluid
                        label='Gênero'
                        name="genero"
                        options={getGeneros()}
                        onChange={onChangeSelect}
                        value={form.usuario.genero}
                        placeholder='Gênero'
                      />
                      <Erro text={errors.genero} />
                    </Form.Field>
                    <Form.Field error={!!errors.celular} required>
                      <label htmlFor="celular">Celular</label>
                      <InputMask
                        type="text"
                        id="celular"
                        name="celular"
                        placeholder="(ddd) número"
                        guide={true}
                        value={form.usuario.celular}
                        onChange={onChangeUsuario}
                        mask={[
                          '(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/,
                          /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
                        ]}
                      />
                      <Erro text={errors.celular} />
                    </Form.Field>
                  </Form.Group>
                  <Button
                    className="btn-ui-1"
                    primary
                    onClick={onSubmitDados}
                    disabled={loading}
                    loading={loading}
                    size="tiny"
                  >
                    Alterar Dados
                  </Button>
                </Form>
                <Form loading={loadingLogo} style={{ marginTop: 20 }}>
                  <h3>Logotipo do sistema</h3>
                  <Form.Field error={!!errors.logo} inline>
                    <div style={{ display: "inline-block" }}>
                      <Popup
                        trigger={
                          <label htmlFor="logo" className="label-ui-arquivo">
                            {logo ? (
                              <span>
                                <Icon name="file pdf outline" /> {logo.name}
                              </span>
                            ) : (
                              <span><Icon name="paperclip" /> {getLogoLabel()}</span>
                            )}
                            <Input
                              type="file"
                              id="logo"
                              name="logo"
                              style={{ display: "none" }}
                              onChange={onChangeLogo}
                              accept="image/*"
                            />
                          </label>
                        }
                        content="Selecione um logotipo"
                      />
                      <br />
                      {getLogoLink()}
                    </div>
                    <div className="div-ui-acao">
                      {!!logo && (
                        <Button
                          disabled={loadingLogo}
                          loading={loadingLogo}
                          size="small"
                          className="btn-ui-1"
                          onClick={onSubmitLogo}
                        >
                          Confirmar Alteração
                        </Button>
                      )}
                    </div>
                    <Erro text={errors.logo} />
                  </Form.Field>
                </Form>
              </div>
            </Transition>
            <Transition
              animation="fade down"
              duration={animationTime}
              visible={activeItem === 'Conta'}
            >
              <Form loading={loading}>
                <h3>Dados de acesso</h3>
                <Form.Field>
                  <label htmlFor="email">Email</label>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Info"
                    onChange={onChangeUsuario}
                    value={form.usuario.email}
                  />
                  <Erro text={errors.email} />
                </Form.Field>
                <Form.Field style={{ marginBottom: "40px" }}>
                  <Button
                    className="btn-ui-1"
                    primary
                    onClick={onSubmitEmail}
                    disabled={loading}
                    loading={loading}
                    size="tiny"
                  >
                    <Icon name="at" /> Alterar Email
                  </Button>
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field error={!!errors.senhaAtual}>
                    <label htmlFor="senhaAtual">Senha atual</label>
                    <Input
                      id="senhaAtual"
                      name="senhaAtual"
                      type="password"
                      placeholder="Senha Atual"
                      onChange={onChangeSenha}
                      value={form.senha.senhaAtual}
                    />
                    <Erro text={errors.senhaAtual} />
                  </Form.Field>
                  <Form.Field error={!!errors.novaSenha}>
                    <label htmlFor="novaSenha">Nova senha</label>
                    <Input
                      id="novaSenha"
                      name="novaSenha"
                      type="password"
                      placeholder="Nova Senha"
                      onChange={onChangeSenha}
                      value={form.senha.novaSenha}
                    />
                    <Erro text={errors.novaSenha} />
                  </Form.Field>
                  <Form.Field error={!!errors.novaSenha2}>
                    <label htmlFor="novaSenha2">Confirme a nova senha</label>
                    <Input
                      id="novaSenha2"
                      name="novaSenha2"
                      type="password"
                      placeholder="Confirme a nova senha"
                      onChange={onChangeSenha}
                      value={form.senha.novaSenha2}
                    />
                    <Erro text={errors.novaSenha2} />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <Button
                    className="btn-ui-1"
                    primary
                    onClick={onSubmitSenha}
                    disabled={loading}
                    loading={loading}
                    size="tiny"
                  >
                    <Icon name="key" /> Alterar Senha
                  </Button>
                </Form.Field>
              </Form>
            </Transition>
            <Transition
              animation="fade down"
              duration={animationTime}
              visible={activeItem === 'Integ'}
            >
              <List verticalAlign="middle">
                <Integracao
                  label="Superlógica"
                  description={<>Sincronização das unidades cadastradas no sistema <i>Superlógica</i></>}
                  ativo={superlogicaForm.ativo}
                  pendente={superlogicaForm.pendente}
                  onClick={changeStateSuperlogica}
                  onCancel={cancelSuperlogica}
                >
                  {(superlogicaForm.ativo || superlogicaForm.pendente) && (
                    <Form loading={superlogicaForm.loading} style={{ marginTop: 20 }}>
                      <Form.Group widths="equal">
                        <Form.Field error={!!superlogicaForm.errors.appToken} required>
                          <label htmlFor="appToken">App Token</label>
                          <Input
                            id="appToken"
                            name="appToken"
                            placeholder="App Token"
                            onChange={onChangeDadosSuperlogica}
                            value={superlogicaForm.dados.appToken}
                            disabled={superlogicaForm.ativo}
                          />
                          <Erro text={superlogicaForm.errors.appToken} />
                        </Form.Field>
                        <Form.Field error={!!superlogicaForm.errors.accessToken} required>
                          <label htmlFor="accessToken">Access Token</label>
                          <Input
                            id="accessToken"
                            name="accessToken"
                            placeholder="Access Token"
                            onChange={onChangeDadosSuperlogica}
                            value={superlogicaForm.dados.accessToken}
                            disabled={superlogicaForm.ativo}
                          />
                          <Erro text={superlogicaForm.errors.accessToken} />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  )}
                </Integracao>
              </List>
            </Transition>
          </Segment>
        </Container>
      </div>
    </div>
  );
};

export default Perfil;
