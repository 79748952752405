export const initState = {
  notificacao: {
    arquivo: '',
    usuario: '',
    unidades: [],
    votacao: '',
    tipo: '',
    mensagem: '',
  },
  notificacoes: [],
  errors: {}
}

const notificacao = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_NOTIFICACOES_SUCCESS":
      return {
        ...initState,
        notificacoes: action.dados.notificacoes
      };
    case "CREATE_NOTIFICACAO_SUCCESS":
      return {
        ...initState,
        notificacoes: [action.notificacao, ...state.notificacoes]
      };
    case "FIND_BY_ID_NOTIFICACAO_SUCCESS":
      return {
        ...state,
        notificacao: { ...state.notificacao, ...action.notificacao }
      };
    case "UPDATE_NOTIFICACAO_SUCCESS":
      return {
        ...initState,
        notificacao: action.notificacao,
        notificacoes: state.notificacoes.map(notificacao =>
          notificacao._id === action.notificacao._id ? action.notificacao : notificacao
        )
      };
    case "ADD_NOTIFICACAO_ERROR":
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default notificacao;
