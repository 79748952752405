const initState = {
  condominio: {
    nome: "",
    documento: "",
    usarFracaoIdeal: false,
    endereco: {
      cep: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      numero: "",
      uf: "",
      complemento: ""
    }
  },
  condominios: [],
  errors: {},
  habilitado: true
};

const condominio = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_CONDOMINIOS_SUCCESS":
      return {
        ...initState,
        condominios: action.dados.condominios,
      };
    case "CREATE_CONDOMINIO_SUCCESS":
      return {
        ...initState,
        condominios: [action.condominio, ...state.condominios],
      };
    case "FIND_BY_ID_CONDOMINIO_SUCCESS":
      return {
        ...state,
        condominio: { ...state.condominio, ...action.condominio }
      };
    case "UPDATE_CONDOMINIO_SUCCESS":
      return {
        ...initState,
        condominio: action.condominio,
        condominios: state.condominios.map(condominio => {
          if (condominio._id === action.condominio._id)
            return action.condominio;
          return condominio;
        })
      };
    case "ADD_UNIDADE_CONDOMINIO":
      return {
        ...state,
        condominio: {
          ...state.condominio,
          unidades: [...state.condominio.unidades, action.unidade]
        }
      };
    case "ADD_CONDOMINIO_ERROR":
      return {
        ...state,
        errors: action.errors
      };
    case "VALIDAR_QUANTIDADE_CONDOMINIO_SUCCESS":
      return {
        ...state,
        habilitado: action.habilitado
      };
    default:
      return state;
  }
};

export default condominio;
