import {
  TOKEN_CONFIRMACAO_TROCA_EMAIL_CONFIRMADO,
  USUARIO_CADASTRADO,
  USUARIO_DESLOGADO,
  USUARIO_LOGADO
} from "./types";

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USUARIO_CADASTRADO:
      return action.user;
    case USUARIO_LOGADO:
      return action.user;
    case 'UPDATE_USUARIO_SUCESSO':
    case TOKEN_CONFIRMACAO_TROCA_EMAIL_CONFIRMADO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        }
      };
    case 'SALVAR_INTEGRACAO_SUCESSO':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.usuario,
        }
      };
    case 'SALVAR_NOVO_LOGOTIPO':
      return {
        ...state,
        user: {
          ...state.user,
          logotipo: action.logotipo,
        },
      };
    case USUARIO_DESLOGADO:
      return {};
    default:
      return state;
  }
}
