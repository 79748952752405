export const initState = {
  responsavel: {
    nome: "",
    email: "",
    tipoDocumento: "CPF",
    documento: "",
    celular: "",
    hash: "",
    ativo: false
  },
  tiposDocumentos: [
    { key: 'CPF', value: 'CPF', text: 'CPF' },
    { key: 'CNPJ', value: 'CNPJ', text: 'CNPJ' }
  ],
  responsaveis: [],
  errors: {}
}

const responsavel = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_RESPONSAVEIS_SUCCESS':
      return {
        ...initState,
        responsaveis: action.dados.responsaveis,
      };
    case 'CREATE_RESPONSAVEL_SUCCESS':
      return {
        ...initState,
        responsaveis: [action.responsavel, ...state.responsaveis],
      };
    case 'FIND_BY_ID_RESPONSAVEL_SUCCESS':
      return {
        ...initState,
        responsavel: { ...state.responsavel, ...action.responsavel }
      };
    case 'FIND_BY_DOCUMENTO_RESPONSAVEL_SUCCESS':
      return {
        ...state,
        responsavel: { ...state.responsavel, ...action.responsavel }
      };
    case 'UPDATE_RESPONSAVEL_SUCCESS':
      return {
        ...initState,
        responsaveis: state.responsaveis.map(responsavel => {
          if (responsavel._id === action.responsavel._id)
            return action.responsavel;
          return responsavel;
        })
      };
    case 'RESET_RESPONSAVEL_STATE':
      return {
        ...initState
      };
    case 'ADD_RESPONSAVEL_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    case 'ADD_FIND_BY_DOCUMENTO_RESPONSAVEL_ERROR':
      return {
        ...state,
        responsavel: initState.responsavel,
        errors: action.errors
      }
    default:
      return state;
  }
}

export default responsavel;