import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = votacao => ({
  type: 'CREATE_VOTACAO_SUCCESS',
  votacao
});

const findSuccess = (dados) => ({
  type: 'FIND_VOTACOES_SUCCESS',
  dados,
});

const findByIdSuccess = ({ votacao, listaPresenca }) => ({
  type: 'FIND_BY_ID_VOTACAO_SUCCESS',
  votacao,
  listaPresenca
});

const findDocumentoByIdAndUpdateSuccess = votacao => ({
  type: 'UPDATE_DOCUMENTO_VOTACAO_SUCCESS',
  votacao
});

const findByIdAndUpdateSuccess = ({ votacao, listaPresenca }) => ({
  type: 'UPDATE_VOTACAO_SUCCESS',
  votacao,
  listaPresenca
});

const toggleExibirVotacaoSuccess = (votacao) => ({
  type: 'TOGGLE_EXIBIR_VOTACAO_SUCCESS',
  payload: votacao,
});

const addError = errors => ({
  type: 'ADD_VOTACAO_ERROR',
  errors
});

export const create = (dados) => async dispatch => {
  return api.votacao.create(dados).then(async data => {
    dispatch(createSuccess(data));
    return await data;
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findById = id => async dispatch => {
  return api.votacao.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const find = (condominio) => async dispatch => {
  return api.votacao.find(condominio).then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findDocumentoByIdAndUpdate = (id, data) => async dispatch => {
  return api.votacao.findDocumentoByIdAndUpdate(id, data).then(data => {
    return dispatch(findDocumentoByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.votacao.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const findByIdAndUpdateExtra = (id, data) => async dispatch => {
  return api.votacao.findByIdAndUpdateExtra(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const iniciarVotacao = (id, data) => async dispatch => {
  return api.votacao.iniciarVotacao(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const finalizarVotacao = (id, condominio) => async dispatch => {
  return api.votacao.finalizarVotacao(id, condominio).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const gerarRelatorioPresenca = (id, condominio) => async dispatch => {
  return api.votacao.gerarRelatorioPresenca(id, condominio).then(data => {
    return data;
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const gerarRelatorioResultadoVotacao = (id, condominio) => async dispatch => {
  return api.votacao.gerarRelatorioResultadoVotacao(id, condominio).then(data => {
    return data;
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const gerarRelatorioChatVotacao = (id, condominio) => async dispatch => {
  return api.votacao.gerarRelatorioChatVotacao(id, condominio).then(data => {
    return data;
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const gerarRelatorioAtaVotacao = (id, condominio) => async dispatch => {
  return api.votacao.gerarRelatorioAtaVotacao(id, condominio).then(data => {
    return data;
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};

export const toggleExibirVotacao = (id) => async dispatch => {
  return api.votacao.toggleExibirVotacao(id).then(data => {
    return dispatch(toggleExibirVotacaoSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
};
