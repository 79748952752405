import socketio from 'socket.io-client';

const getUrlFormated = url => {
  if (url.indexOf("http:") === 0 || url.indexOf("https:") === 0) {
    return url;
  }
  return window.location.protocol === "https:"
         ? `https://${url}`
         : `http://${url}`;
};

const url = getUrlFormated(process.env.REACT_APP_SOCKET_URL);

const socket = socketio(url, {
  autoConnect: false,
  path: '/socket.io',
  transports: ['websocket'],
  secure: true,
});

function connect() {
  console.log('Connect');
  socket.connect();
}

function disconnect() {
  if (socket.connected) {
    socket.disconnect();
  }
}

export {connect, disconnect, socket};
