import React from 'react';
import { Segment, Grid, Icon, Popup, Button } from 'semantic-ui-react';

const Link = ({ link, submitExcluir }) => {
  const excluir = () => submitExcluir(link);

  return (
    <Segment style={{ marginBottom: "10px", marginTop: "10px" }} key={link._id}>
      <Grid verticalAlign="middle" stackable>
        <Grid.Column width={12}>
          <p>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <Icon name="linkify" /> {link.nome}
            </a>
          </p>
        </Grid.Column>
        <Grid.Column width={4} className="action-ui-manter">
          <Popup
            content="Excluir Link"
            position="top center"
            size="small"
            trigger={
              <Button
                as="span"
                icon
                color="violet"
                basic
                size="tiny"
                onClick={excluir}
              >
                <Icon name='trash alternate' />
              </Button>
            }
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default Link;
