import React, { useState } from "react";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import { Link } from "react-router-dom";
import { Accordion, Container, Icon, Transition } from "semantic-ui-react";

export default function Ajuda(props) {
  const [activeIndex, setActiveIndex] = useState(null);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    setActiveIndex(activeIndex === index ? -1 : index);
  }

  return (
    <div id="home">
      <MenuHorizontal {...props} />
      <div className="ui-pagina-login-gradient-img" />
      <div className="ui-pagina-ajuda">
        <Container className="text-center">
          <h1>Como podemos ajudar?</h1>
        </Container>
        <Transition
          animation="fade up"
          duration={{ show: 1000, hide: 0 }}
          visible={true}
          transitionOnMount={true}
        >
          <Container className="ui-ajuda-itens">
            <Accordion fluid styled className="ui-ajuda-accordion">
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>O que é sistema de Votação Eletrônica?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <p className="ui-ajuda-texto">
                  Acesse a página de <Link to="/cadastro">cadastro</Link>{" "}
                  preencha o formulário com as informações solicitadas,
                  lembre-se que sua senha precisa ter pelo menos um número e uma
                  letra, clique em cadastrar e pronto, você receberá uma
                  mensagem no email informado para ativar sua conta e poder
                  utilizar todos os recursos da plataforma.
                </p>
                <p className="ui-ajuda-texto">
                  É um sistema fornecido por nós para que você, síndico, possa
                  gerenciar e cadastrar suas Assembleias Virtuais de forma
                  consistentemente organizada, permitindo que todos os
                  condôminos participem das reuniões do conforto de suas casas
                  ou onde quer que estejam.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>O que diz a Lei nº 14.010 DE 10 DE JUNHO DE 2020?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <p className="ui-ajuda-texto">
                  Conforme o Art. 5, a assembleia geral, inclusive para os fins
                  do art. 59 do Código Civil, até 30 de outubro de 2020, poderá
                  ser realizada por meios eletrônicos, independentemente de
                  previsão nos atos constitutivos da pessoa jurídica.
                </p>
                <p className="ui-ajuda-texto">
                  Parágrafo único. A manifestação dos participantes poderá
                  ocorrer por qualquer meio eletrônico indicado pelo
                  administrador, que assegure a identificação do participante e
                  a segurança do voto, e produzirá todos os efeitos legais de
                  uma assinatura presencial.
                </p>
                <p className="ui-ajuda-texto">
                  Art. 12, a assembleia condominial, inclusive para os fins dos
                  arts. 1.349 e 1.350 do Código Civil, e a respectiva votação
                  poderão ocorrer, em caráter emergencial, até 30 de outubro de
                  2020, por meios virtuais, caso em que a manifestação de
                  vontade de cada condômino será equiparada, para todos os
                  efeitos jurídicos, à sua assinatura presencial.
                </p>
                <p className="ui-ajuda-texto">
                  Parágrafo único. Não sendo possível a realização de assembleia
                  condominial na forma prevista no caput, os mandatos de síndico
                  vencidos a partir de 20 de março de 2020 ficam prorrogados até
                  30 de outubro de 2020.
                </p>
                <p className="ui-ajuda-texto">
                  <a href="http://www.planalto.gov.br/ccivil_03/_ato2019-2022/2020/lei/L14010.htm">
                    Para ver a Lei na integra, acesse Aqui
                  </a>
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como ficam os moradores que não usam nada de tecnologia?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p className="ui-ajuda-texto">
                  Nosso app é muito simples de ser utilizado! Todo o cadastro é
                  feito pelo síndico ou administradora. O condômino deve apenas
                  clicar na opção de seu interesse pelo celular. Hoje a maioria
                  das pessoas estão precisando e usando algum tipo de aplicativo
                  de celular. Na nossa página disponibilizamos vídeos com passo
                  a passo para cada funcionalidade.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  O Vote condomínio funciona em qualquer lugar do Brasil?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p className="ui-ajuda-texto">
                  Sim, em todo o território nacional
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como os síndicos e administradoras usam a plataforma?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <p className="ui-ajuda-texto">
                  Temos um vídeo no nosso canal que explica as principais
                  funções do App e como utilizá-las:
                </p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/KUQ6iQKT84E"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Como os síndicos e administradoras usam a plataforma?"
                    allowFullScreen
                  />
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 5}
                index={5}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Vocês tem algum vídeo que explique para os condôminos como
                  usar o app?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5}>
                <p className="ui-ajuda-texto">Sim! Temos um canal no YouTube</p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/q4CMQVwn47c?start=111"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Vocês tem algum vídeo que explique para os condôminos como
                    usar o app?"
                    allowFullScreen
                  />
                </div>
                <p className="ui-ajuda-texto">
                  que possui vídeos explicativos tanto da visão do condômino
                  quanto do síndico ou administradora
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 6}
                index={6}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como os condôminos podem atualizar o App?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 6}>
                <p className="ui-ajuda-texto">
                  Temos vídeos no nosso do YouTube que explica tanto na versão
                  IOS
                </p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/uQnWImFPq0c"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="IOS"
                    allowFullScreen
                  />
                </div>
                <p className="ui-ajuda-texto">quanto na Android</p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/yE5Kid6OQf4"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Android"
                    allowFullScreen
                  />
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 7}
                index={7}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como seria a transmissão online?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 7}>
                <p className="ui-ajuda-texto">
                  O síndico/administradora pode criar uma reunião numa
                  plataforma tipo Zoom e cadastrar na assembleia. Por dentro do
                  app o condômino pode acessar esse link e abrir a
                  webconferência. A partir daí, as perguntas / pautas da votação ficam
                  abertas para discussão também via chat e voto.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 8}
                index={8}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Ao término da Assembleia, é possível salvar ou gravar a
                  reunião no Google Meets ou no Zoom?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 8}>
                <p className="ui-ajuda-texto">
                  Qualquer documento pode ser anexado e colocado em arquivos de
                  uma determinada reunião.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 9}
                index={9}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como coloco o link do Google Meets ou do Zoom para enviar pelo
                  Vote Condomínio?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 9}>
                <p className="ui-ajuda-texto">
                  Para o link da reunião, existe um campo quando cadastrar a
                  votação para que informe esse link. Muito simples também. O
                  link ficará disponível para os condôminos no app.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 10}
                index={10}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  É possível utilizar o computador para acessar o App ou apenas
                  o celular?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 10}>
                <p className="ui-ajuda-texto">
                  O síndico/administradora utiliza o computador e o condômino
                  apenas pelo celular.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 11}
                index={11}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>E sobre unidade com procuração? Como é o processo?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 11}>
                <p className="ui-ajuda-texto">
                  Na prática, não precisamos mais de procuração, uma vez que
                  todos podem votar. De qualquer forma, o síndico pode cadastrar
                  o procurador na plataforma ao invés do proprietário,
                  arquivando a procuração.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 12}
                index={12}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Com esse sistema e observando a nova Lei, preciso enviar a
                  convocação por e-mail?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 12}>
                <p className="ui-ajuda-texto">
                  Você pode fazer a convocação pelo próprio app. Assim que
                  cadastrar a reunião existe a possibilidade do envio da
                  convocação, onde todos os condôminos receberão por email,
                  dando amplo conhecimento a todos.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 13}
                index={13}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como é feita a lista de presença?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 13}>
                <p className="ui-ajuda-texto">
                  A lista de presença é feita em formato pdf, para ter acesso a
                  ela o síndico ou administradora deverá clicar em relatórios
                  dentro de gerenciar votações.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 14}
                index={14}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  No aplicativo existe possibilidades do morador abrir reuniões?
                  Exemplo discutir alguma atitude do síndico com outros
                  moradores.
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 14}>
                <p className="ui-ajuda-texto">
                  Depois que o síndico ou administradora abrir a reunião, abaixo
                  de cada pergunta da votação tem um chat, onde os condôminos
                  podem discutir suas opiniões e dar sugestões, que por sua vez
                  fica registrado na plataforma.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 15}
                index={15}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como condômino, como faço para acessar o app?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 15}>
                <p className="ui-ajuda-texto">
                  Logo após você instalar o app, você receberá um código de
                  ativação de seu síndico ou administradora por e-mail ou sms
                  para acessar o app e começar a participar das assembleias de
                  forma virtual.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 16}
                index={16}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>O que faço no caso de inadimplência, por exemplo?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 16}>
                <p className="ui-ajuda-texto">
                  Nesse caso você poderá bloquear a unidade e se desejar poderá
                  ativá-la futuramente.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 17}
                index={17}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  O cadastramento dos proprietários e unidades. Como funciona?
                  Passamos uma lista para vcs com os dados?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 17}>
                <p className="ui-ajuda-texto">
                  Para a entrada dos dados das unidades, temos duas
                  possibilidades. A primeira é a digitação, mais demorado. A
                  segunda, mais prática, existe uma funcionalidade onde você
                  mesmo consegue importar seus dados a partir de uma planilha
                  eletrônica. Muito prático e rápido!
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 18}
                index={18}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Quanto a validade da votação, ata da assembleia e registro em
                  cartório da mesma?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 18}>
                <p className="ui-ajuda-texto">
                  O sistema já gera a ata com todos os resultados, permitindo
                  que outros textos possam ser inseridos. A ata pode ser
                  registrada em cartório normalmente. Fizemos uma parceria com
                  um cartório virtual que a ata pode ser enviada eletronicamente
                  por dentro do aplicativo e você recebe de volta
                  eletronicamente a ata registrada. Nesse caso, esse é um
                  serviço opcional e o registro deve ser pago diretamente ao
                  cartório. Não temos nenhuma participação comercial nisso,
                  apenas facilitando a vida dos clientes. Mas é opcional, pode
                  continuar registrando no seu cartório normalmente.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 19}
                index={19}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como autentica o usuário para confirmar que é um condômino
                  realmente?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 19}>
                <p className="ui-ajuda-texto">
                  Isso é feito via Token de acesso que o usuário recebe via
                  Email no endereço eletrônico cadastrado na plataforma.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 20}
                index={20}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como usar um importar um arquivo CSV para cadastrar as
                  unidades?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 20}>
                <p className="ui-ajuda-texto">
                  Temos um vídeo no nosso canal do YouTube que explica como
                  importar arquivos nesse formato:
                </p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/1nCrSlo6C8s?start=2"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Como usar um importar um arquivo CSV para cadastrar as unidades?"
                    allowFullScreen
                  />
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 21}
                index={21}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como anexar arquivos grandes?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 21}>
                <p className="ui-ajuda-texto">
                  No caso de arquivos grandes sugerimos usar o Google Drive,
                  temos um vídeo no nosso canal do YouTube:
                </p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/jUAjd-gXG-o"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Como anexar arquivos grandes?"
                    allowFullScreen
                  />
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 22}
                index={22}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Como os condôminos podem saber mais sobre os candidatos a
                  síndico?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 22}>
                <p className="ui-ajuda-texto">
                  A plataforma foi construída para fazer qualquer tipo de
                  votação. Você pode abrir uma votação com os nomes dos
                  candidatos. O cadastro é feito pelo síndico no site.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 23}
                index={23}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como faço minha assinatura?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 23}>
                <p className="ui-ajuda-texto">
                  <Link to="/cadastro">Faça já seu cadastro Aqui</Link>
                </p>
                <p className="ui-ajuda-texto">
                  Em seguida, basta adquirir as unidades que deseja para seu
                  Condomínio / Sociedade / Associação. Você pode assistir nosso vídeo no YouTube que
                  explica detalhadamente como fazer a assinatura:
                </p>
                <div className="ui-ajuda-div-iframe">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/XTYmt0UYkWo?start=18"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Como anexar arquivos grandes?"
                    allowFullScreen
                  />
                </div>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 24}
                index={24}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Depois de ter assinado o pacote anual posso adicionar mais
                  unidades?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 24}>
                <p className="ui-ajuda-texto">
                  Sim! Você poderá adicionar mais unidades do seu Condomínio / Sociedade / Associação
                  futuramente.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 25}
                index={25}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>
                  Depois de ter assinado o pacote anual posso adicionar mais
                  unidades?
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 25}>
                <p className="ui-ajuda-texto">
                  Sim! Você poderá adicionar mais unidades do seu Condomínio / Sociedade / Associação
                  futuramente.
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 26}
                index={26}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como funciona o pagamento?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 26}>
                <p className="ui-ajuda-texto">
                  * Pacote anual com número Ilimitado de Assembleias e Votações
                </p>
                <p className="ui-ajuda-texto">
                  Você paga por um plano anual, sendo{" "}
                  <span className="ui-texto-destaque">
                    R$ 199,00 fixo + R$ 2,00
                  </span>{" "}
                  para cada unidade.
                </p>
                <p className="ui-ajuda-texto">
                  Veja um exemplo: Para um Condomínio / Sociedade / Associação de{" "}
                  <span className="ui-texto-destaque">50 unidades</span>, o
                  valor pago{" "}
                  <span className="ui-texto-destaque">anualmente</span> é de{" "}
                  <span className="ui-texto-destaque">R$ 299,00</span>, o que
                  daria{" "}
                  <span className="ui-texto-destaque">
                    R$ 0,50 centavos por mês por unidade
                  </span>{" "}
                  para reuniões{" "}
                  <span className="ui-texto-destaque">ilimitadas</span>.
                </p>
                <p className="ui-ajuda-texto">
                  Para calcular o valor da anuidade, tem um simulador no nosso
                  site,{" "}
                  <a href="https://votecondominio.com.br/#apps-craft-pricing">
                    https://votecondominio.com.br/#apps-craft-pricing
                  </a>
                  . Esse valor é por ano! E sem limite de assembleias ou
                  votações!
                </p>
              </Accordion.Content>

              <Accordion.Title
                active={activeIndex === 27}
                index={27}
                onClick={handleClick}
                className="ui-ajuda-titulo"
              >
                <Icon name="dropdown" />
                <span>Como é feito o pagamento para utilizar o app?</span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 27}>
                <p className="ui-ajuda-texto">
                  O pagamento pode ser feito por boleto bancário ou cartão de
                  crédito. Lembramos que o pagamento por cartão de crédito é
                  liberado em poucos minutos e você já pode realizar sua
                  assembleia virtual! No caso de pagamento com boleto bancário,
                  precisamos aguardar a compensação bancária, em média dois dias
                  úteis.
                </p>
              </Accordion.Content>
            </Accordion>
          </Container>
        </Transition>
      </div>
    </div>
  );
}
