const initState = {
  documento: {
    nome: "",
    key: "",
    url: "",
    pergunta: ""
  },
  documentos: [],
  errors: {}
}

const documento = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_DOCUMENTOS_SUCCESS':
      return {
        ...initState,
        documentos: action.dados.documentos
      };
    case 'FIND_BY_ID_DOCUMENTO_SUCCESS':
      return {
        ...initState,
        documento: { ...state.documento, ...action.documento }
      };
    case 'ADD_DOCUMENTO_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default documento;