import api from "../api";
import errorHandler from "../utils/errorHandler";

export const emailContatoSuccess = usuario => ({
  type: 'EMAIL_CONTATO_SUCCESS',
  usuario
});

const addError = errors => ({
  type: 'ADD_CONTATO_ERROR',
  errors
})

export const enviarEmailContato = (dados) => async dispatch => {
  return api.sistema.enviarEmailContato(dados).then(data => {
    return dispatch(emailContatoSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}