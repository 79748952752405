import { combineReducers } from "redux";
import user from "./userReducer";
import condominio from "./condominioReducer";
import unidade from "./unidadeReducer";
import responsavel from "./responsavelReducer";
import votacao from "./votacaoReducer";
import pergunta from "./perguntaReducer";
import resposta from "./respostaReducer";
import documento from "./documentoReducer";
import sistema from "./sistemaReducer";
import pagamento from "./pagamentoReducer";
import notificacao from "./notificacaoReducer";

const reducers = combineReducers({
  user,
  condominio,
  unidade,
  responsavel,
  votacao,
  pergunta,
  resposta,
  documento,
  sistema,
  pagamento,
  notificacao
});

export default reducers

