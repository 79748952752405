const initState = {
  pagamento: {
    link: ""
  },
  pagamentos: [],
  errors: {}
}

const pagamento = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_PAGAMENTOS_SUCCESS':
      return {
        ...initState,
        pagamentos: action.dados.pagamentos,
      };
    case 'CREATE_PAGAMENTO_SUCCESS':
      return {
        ...initState,
        pagamentos: [action.pagamento, ...state.pagamentos]
      };
    case 'ADD_PAGAMENTO_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default pagamento;