import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from '../../layouts/MenuVertical';
import { Grid, Icon, Divider, Button, Loader } from "semantic-ui-react";
import Votacao from './Votacao';
import CondominioVencido from '../admin/CondominioVencido';
import api from '../../../api';

const Votacoes = ({ match: { params } }) => {
  const [condominio, setCondominio] = useState({});
  const [votacoes, setVotacoes] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const init = async () => {
        try {
          const cond = await api.condominio.findSimpleById(params.id);
          setCondominio(cond);
          loadVotacoes(cond);
        } catch (e) {
          setErrors(e.data.errors);
        }

        setLoading(false);
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadVotacoes = async (cond) => {
    try {
      const votacoesRes = await api.votacao.find(cond ? cond._id : condominio._id).then(it => it.votacoes);
      setVotacoes(votacoesRes);
    } catch (e) {
      setErrors(e.data.errors);
    }
  };

  const getList = votacoes =>
    !!votacoes.length ? (
      <Grid columns={3} doubling stackable style={{ marginTop: "20px" }}>
        {votacoes.map(votacao =>
          <Grid.Column key={votacao._id}>
            <Votacao votacao={votacao} atualizarLista={loadVotacoes} />
          </Grid.Column>
        )}
      </Grid>
    ) : (
      <div className="empty-illustration-1">
        <img src="/images/illustrations/urna-votacao.svg" alt="Nenhuma Unidade Encontrada" />
      </div>
    );

  return (
    <>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} />
        <CondominioVencido condominio={condominio}>
          <Button
            className="btn-ui-1"
            as={Link}
            to={`/condominios/${condominio._id}/votacoes/cadastrar`}
            size="large"
          >
            <Icon name="plus" /> Cadastrar Votação
          </Button>
        </CondominioVencido>
        <Divider horizontal>Votações</Divider>
        {loading ? (
          <Loader active inline='centered' style={{ marginTop: "50px" }} />
        ) : getList(votacoes)}
      </div>
    </>
  );
};

export default Votacoes;
