/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

const TOTAL_ITEMS_PER_UPDATE = 20;

const InfinitySelect = props => {
  const ref = useRef();
  const list = useRef();
  const pending = useRef(false);
  const changeViaComponent = useRef(false);

  const [scroll, setScroll] = useState(0);
  const [options, setOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(TOTAL_ITEMS_PER_UPDATE);
  const [value, setValue] = useState([]);

  useEffect(() => () => dettachEvent(), []);
  useEffect(() => updateOptions(), [currentIndex, props.options]);
  useEffect(() => attachEvent(), [ref]);

  useEffect(
    () => {
      setValue(Array.isArray(props.value) ? props.value : [props.value]);
    },
    [props.value]
  );

  useEffect(
    () => {
      if (changeViaComponent.current && props.onChange) {
        props.onChange(null, {
          name: props.name,
          value: getValue(),
        });
      }
      changeViaComponent.current = false;
    },
    [value]
  );

  useEffect(
    () => {
      if (!pending.current) {
        const totalScrollSpace = list.current.scrollHeight - list.current.clientHeight;
        if (scroll >= totalScrollSpace - 10 && currentIndex < props.options.length) {
          pending.current = true;
          setCurrentIndex(currentIndex + TOTAL_ITEMS_PER_UPDATE);
        }
      }
    },
    [scroll]
  );

  const onScroll = e => setScroll(e.target.scrollTop);

  const attachEvent = () => {
    const _list = ref.current && ref.current.querySelector('[role="listbox"]');
    if (_list) {
      list.current = _list;
      list.current.addEventListener('scroll', onScroll, false);
    }
  };

  const dettachEvent = () => {
    if (list.current) {
      list.current.removeEventListener('scroll', onScroll);
    }
  };

  const updateOptions = () => {
    if (props.options.length > 0) {
      const range = Array.from(Array(currentIndex), (_, i) => props.options[i])
        .filter(it => it && value.indexOf(it.value) === -1);
      const selecionados = props.options.filter(it => value.indexOf(it.value) !== -1);
      setOptions([...range, ...selecionados]);
    } else {
      setOptions([]);
    }
    pending.current = false;
  };

  const onClose = () => {
    setCurrentIndex(TOTAL_ITEMS_PER_UPDATE);
    if (props.onClose) {
      props.onClose();
    }
  };

  const onChange = (_, selectProps) => {
    changeViaComponent.current = true;
    setValue(props.multiple ? selectProps.value : [selectProps.value]);
  };

  const getValue = () => {
    let result = value;
    if (!props.multiple) {
      result = value.length === 0 ? null : value[0];
    }
    return result;
  };

  return (
    <div ref={ref}>
      <Form.Select
        {...props}
        value={getValue()}
        search={true}
        options={options}
        scrolling={true}
        onClose={onClose}
        onChange={onChange}
      />
    </div>
  );
};

export default InfinitySelect;
