import React from 'react';
import moment from 'moment';
import { Transition, Card, Icon, Button } from 'semantic-ui-react';
import 'moment/locale/pt-br';
import { Link } from 'react-router-dom';
moment.locale('pt-BR');

const Responsavel = ({ responsavel, condominio, className, readOnly, animation }) => {
  return (
    <Transition duration={!isNaN(animation) ? animation : 500} animation="fade up" transitionOnMount={true}>
      <Card style={{ width: "100%", padding: "10px" }}>
        <Card.Content>
          <Button as={Link} to={`/condominios/${condominio._id}/responsaveis/editar/${responsavel._id}`} floated="right" className="btn-ui-1">Editar</Button>
          <Card.Header style={{ wordWrap: "break-word" }}>{responsavel.nome}</Card.Header>
          <Card.Description>
            {!!responsavel.celular ?
              <p><Icon name="phone" /> <a href={`tel:${Array.from(responsavel.celular.trim()).map(n => isNaN(parseInt(n)) ? "" : n).join("")}`}>{responsavel.celular}</a></p>
              :
              <p><Icon name="phone" /> Número não informado</p>
            }
            {!!responsavel.email && <p style={{ wordWrap: "break-word" }}><Icon name="mail" /> {responsavel.email}</p>}
          </Card.Description>
        </Card.Content>
      </Card>
    </Transition>
  )
};

export default Responsavel;
