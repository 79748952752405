import React from 'react';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './CondominioVencido.css';

const CondominioVencido = ({ condominio, children }) => {
  const usuario = useSelector(state => state.user.user);

  const getDate = () =>
    moment(condominio.dataVencimento).format('DD/MM/YYYY');

  const getTime = () =>
    moment(condominio.dataVencimento).format('HH:mm');

  const isDemo = () =>
    usuario && usuario.tipo === 'demo';

  const isAtivo = () =>
    condominio.dataVencimento && moment(condominio.dataVencimento) >= moment();

  return (
    <div className="comprar-unidades">
      {isDemo() || isAtivo() ? children : (
        condominio.dataVencimento ? (
          <>
            <span>A anuidade deste Condomínio / Sociedade / Associação venceu às {getTime()} de {getDate()}</span>
            <Button
              className="btn-ui-1"
              as={Link}
              to={`/condominios/${condominio._id}/comprar`}
            >
              Renovar Condomínio / Sociedade / Associação
            </Button>
          </>
        ) : (
          <>
            <span>É necessário que o Condomínio / Sociedade / Associação possua unidades para utilizar esta funcionalidade.</span>
            <Button
              className="btn-ui-1"
              as={Link}
              to={`/condominios/${condominio._id}/comprar`}
            >
              Comprar Unidades
            </Button>
          </>
        )
      )}
    </div>
  );
};

export default CondominioVencido;
