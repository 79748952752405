import React, { Component } from "react";
import PropTypes from "prop-types";
import { Message, Container, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reverterTrocaEmail } from "../../../actions/auth";

class ReverterTrocaEmail extends Component {
  state = {
    loading: true,
    success: false,
    error: null
  };

  componentDidMount() {
    this.props
      .reverterTrocaEmail(this.props.match.params.token)
      .then(() => this.setState({ loading: false, success: true }))
      .catch((err) => this.setState({ loading: false, success: false, error: err.data.error }));
  }

  render() {
    const { loading, success, error } = this.state;
    return (
      <Container>
        {loading && (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Header>Validando alteração de email</Message.Header>
          </Message>
        )}

        {!loading &&
          success && (
            <Message success icon>
              <Icon name="checkmark" />
              <Message.Content>
                <Message.Header>
                  Tudo certo! seu email for recuperado.
                </Message.Header>
                <p><Link to="/login">Ir para o Dashboard</Link></p>
              </Message.Content>
            </Message>
          )}

        {!loading &&
          !success && (
            <Message negative icon>
              <Icon name="warning sign" />
              <Message.Content>
                <Message.Header>
                  Ooops. {error}
                </Message.Header>
              </Message.Content>
            </Message>
          )}
      </Container>
    );
  }
}

ReverterTrocaEmail.propTypes = {
  reverterTrocaEmail: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default connect(null, { reverterTrocaEmail })(ReverterTrocaEmail);
