import React, { useState } from 'react';
import { Button, List } from 'semantic-ui-react';

const Integracao = ({ label, description, ativo, pendente, onClick, onCancel, children }) => {
  const [isOver, setIsOver] = useState(false);

  const onMouseOver = () => ativo && setIsOver(true);
  const onMouseOut = () => ativo && setIsOver(false);

  const getButtonLabel = () => {
    let result = 'Ativar';
    if (ativo) {
      result = isOver ? 'Desativar' : 'Ativo';
    } else if (pendente) {
      result = 'Confirmar';
    }
    return result;
  };

  return (
    <List.Item className="animated-list-item">
      <List.Content>
        <List.Header className="notification-header">{label}</List.Header>
        <List.Description style={{ fontSize: '1.1em', marginTop: 5 }}>{description}</List.Description>
        {children && <List.Description style={{ marginTop: 5 }}>{children}</List.Description>}
        <div className="btn-right" style={{ top: 20 }}>
          {pendente && (
            <Button
              onClick={onCancel}
              negative={true}
              size="large"
            >
              Cancelar
            </Button>
          )}
          <Button
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            positive={ativo && !isOver}
            negative={ativo && isOver}
            primary={!ativo && pendente}
            className={ativo || pendente ? '' : 'btn-ui-1'}
            size="large"
            style={{ width: 120 }}
          >
            {getButtonLabel()}
          </Button>
        </div>
      </List.Content>
    </List.Item>
  );
};

export default Integracao;
