import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { Container, Menu, Icon, Segment, Form, Input, Popup, Button } from 'semantic-ui-react';
import Erro from '../../layouts/mensagens/Erro';
import InputMask from 'react-text-mask';
import api from '../../../api';
import { formatDocumento } from '../../../utils/formatador';
import InfinitySelect from '../../layouts/InfinitySelect';
import * as FormValidator from "../../../utils/formValidator";
import { DATA_PATTERN, HORA_PATTERN } from "../../../utils/patterns";

const CadastrarVotacao = ({ match: { params }, history }) => {
  const [condominio, setCondominio] = useState({});
  const [responsaveis, setResponsaveis] = useState([]);
  const [convocacao, setConvocacao] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [votacao, setVotacao] = useState({
    condominio: params.id,
    dataFinalReuniao: '',
    dataInicioReuniao: '',
    horarioFinalReuniao: '',
    horarioReuniao: '',
    local: '',
    presidente: '0',
    secretario: '0',
    titulo: '',
    urlTransmissao: '',
    votacaoIniciada: true,
  });

  useState(() => {
    const init = async () => {
      try {
        setCondominio(await api.condominio.findSimpleById(params.id));
        setResponsaveis(await api.responsavel.find(params.id).then(it => it.responsaveis));
        setLoading(false);
      } catch(e) {
        setErrors(e.data.errors);
      }
    };
    init();
  }, []);

  const getResponsaveis = label => [
    { key: '0', value: '0', text: `Selecione o ${label}` },
    ...responsaveis.map(it => ({
      key: it._id,
      value: it._id,
      text: `${it.nome} - ${formatDocumento(it.documento)}`,
    }))
  ];

  const onChangeFile = ({ target: { files } }) => {
    if (files && files[0] && files[0].type === 'application/pdf') {
      if (FormValidator.sizeLimit(files[0], process.env.REACT_APP_LIMIT_UPLOAD)) {
        setConvocacao(files[0]);
        setErrors({});
      } else {
        setErrors({ arquivo: `Permitido arquivos de até ${process.env.REACT_APP_LIMIT_UPLOAD}mb` });
      }
    }
  };

  const changeVotacao = (name, value) => setVotacao(p => ({ ...p, [name]: value }));
  const onChangeSelect = (_, { name, value }) => changeVotacao(name, value);
  const onChange = ({ target: { name, value } }) => changeVotacao(name, value);

  const validate = () => {
    const errs = {};

    if (FormValidator.isVazio(votacao.titulo)) {
      errs.titulo = 'Titulo é obrigatório';
    }

    if (FormValidator.isVazio(votacao.dataInicioReuniao)) {
      errs.dataInicioReuniao = 'Data início é obrigatório';
    } else if (!FormValidator.isDataVotacao(votacao.dataInicioReuniao)) {
      errs.dataInicioReuniao = 'Digite uma data maior ou igual a data atual';
    }

    if (FormValidator.isVazio(votacao.dataFinalReuniao)) {
      errs.dataFinalReuniao = 'Data limite é obrigatório';
    } else if (!FormValidator.isDataVotacao(votacao.dataFinalReuniao)) {
      errs.dataFinalReuniao = 'Digite uma data maior ou igual a data atual';
    }

    if (!FormValidator.isVazio(votacao.urlTransmissao) && !FormValidator.isUrl(votacao.urlTransmissao)) {
      errs.urlTransmissao = 'Digite uma url válida';
    }

    return errs;
  };

  const buildForm = () => {
    const formData = new FormData();

    formData.append("arquivo", convocacao);

    Object.keys(votacao).forEach(item => {
      formData.append(item, votacao[item]);
    });

    return formData;
  };

  const onSubmit = async () => {
    const errs = validate();
    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      setLoading(true);
      try {
        const result = await api.votacao.create(buildForm());
        history.push(`/condominios/${params.id}/votacoes/editar/${result._id}/perguntas`);
      } catch (e) {
        setErrors(e.data.errors);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard-mini">
        <MenuVertical condominio={condominio} />
        <Container style={{ paddingTop: 10 }}>
          <div className="ui-crud-form">
            <h3>Cadastro de Votação</h3>
            <Menu pointing secondary color="violet" stackable widths={2}>
              <Menu.Item name="sobre" active={true}>
                <Icon name="calendar check" /> Sobre a Reunião
              </Menu.Item>
              <Menu.Item>
                <Icon name="question" /> Perguntas / Pautas
              </Menu.Item>
            </Menu>
            <Segment padded>
              <Form loading={loading} autoComplete="off" size="large">
                <Form.Field error={!!errors.arquivo} inline>
                  <label style={{ display: 'block', marginBottom: 5 }}>Documento de Convocação</label>
                  <label htmlFor="arquivo" className="label-ui-arquivo">
                    {convocacao ? (
                      <span><Icon name="file pdf outline" /> {convocacao.name}</span>
                    ) : (
                      <span><Icon name="paperclip" /> Selecionar documento PDF</span>
                    )}
                    <Input
                      type="file"
                      id="arquivo"
                      name="arquivo"
                      style={{ display: 'none' }}
                      onChange={onChangeFile}
                      accept="application/pdf"
                    />
                  </label>
                  <Erro text={errors.arquivo} />
                  <br />
                </Form.Field>
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.titulo}>
                    <label htmlFor="titulo">Título da votação</label>
                    <Input
                      name="titulo"
                      type="text"
                      placeholder="Título"
                      onChange={onChange}
                      value={votacao.titulo}
                    />
                    <Erro text={errors.titulo} />
                  </Form.Field>
                  <Form.Field error={!!errors.local}>
                    <label htmlFor="local">Local da reunião</label>
                    <Input>
                      <input
                        type="text"
                        id="local"
                        placeholder="Local"
                        name="local"
                        value={votacao.local}
                        onChange={onChange}
                      />
                    </Input>
                    <Erro text={errors.local} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={4}>
                  <Form.Field error={!!errors.dataInicioReuniao}>
                    <label htmlFor="dataInicioReuniao">Data início da reunião</label>
                    <InputMask
                      type="text"
                      id="dataInicioReuniao"
                      name="dataInicioReuniao"
                      mask={DATA_PATTERN}
                      guide={true}
                      placeholder="dia/mês/ano"
                      value={votacao.dataInicioReuniao}
                      onChange={onChange}
                    />
                    <Erro text={errors.dataInicioReuniao} />
                  </Form.Field>
                  <Form.Field error={!!errors.horarioReuniao}>
                    <label htmlFor="horarioReuniao">Horário da reunião</label>
                    <InputMask
                      type="text"
                      id="horarioReuniao"
                      name="horarioReuniao"
                      mask={HORA_PATTERN}
                      guide={true}
                      placeholder="hh:mm"
                      value={votacao.horarioReuniao}
                      onChange={onChange}
                    />
                    <Erro text={errors.horarioReuniao} />
                  </Form.Field>
                  <Form.Field error={!!errors.dataFinalReuniao}>
                    <label htmlFor="dataFinalReuniao">Data limite para votações</label>
                    <InputMask
                      type="text"
                      id="dataFinalReuniao"
                      name="dataFinalReuniao"
                      mask={DATA_PATTERN}
                      guide={true}
                      placeholder="dia/mês/ano"
                      value={votacao.dataFinalReuniao}
                      onChange={onChange}
                    />
                    <Erro text={errors.dataFinalReuniao} />
                  </Form.Field>
                  <Form.Field error={!!errors.horarioFinalReuniao}>
                    <Popup
                      trigger={
                        <label htmlFor="horarioFinalReuniao">Horário limite para votações</label>
                      }
                      content={
                        <p>
                          Após o término da votação os resultados estarão disponíveis após
                          <strong> 5 minutos</strong> para todos os usuários.
                        </p>}
                      size="large"
                    />
                    <InputMask
                      type="text"
                      id="horarioFinalReuniao"
                      name="horarioFinalReuniao"
                      mask={HORA_PATTERN}
                      guide={true}
                      placeholder="hh:mm"
                      value={votacao.horarioFinalReuniao}
                      onChange={onChange}
                    />
                    <Erro text={errors.horarioFinalReuniao} />
                  </Form.Field>
                </Form.Group>
                <Form.Field error={!!errors.urlTransmissao}>
                  <label htmlFor="urlTransmissao">Link de acesso ou transmissão</label>
                  <Input
                    name="urlTransmissao"
                    type="text"
                    placeholder="Link de acesso ou transmissão Zoom, Youtube, Meet, Teams, Skype, entre outros."
                    onChange={onChange}
                    value={votacao.urlTransmissao}
                  />
                  <Erro text={errors.urlTransmissao} />
                </Form.Field>
                <Form.Group widths={2}>
                  <Form.Field>
                    <InfinitySelect
                      fluid
                      label='Presidente'
                      name="presidente"
                      options={getResponsaveis('presidente')}
                      onChange={onChangeSelect}
                      value={votacao.presidente}
                    />
                  </Form.Field>
                  <Form.Field>
                    <InfinitySelect
                      fluid
                      label='Secretário'
                      name="secretario"
                      options={getResponsaveis('secretário')}
                      onChange={onChangeSelect}
                      value={votacao.secretario}
                    />
                  </Form.Field>
                </Form.Group>
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="btn-ui-1"
                    onClick={onSubmit}
                    size="large"
                  >
                    Próximo
                  </Button>
                </div>
              </Form>
            </Segment>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CadastrarVotacao;
