const initState = {
  resposta: {
    titulo: "",
    pergunta: "",
    votos: []
  },
  respostas: [],
  errors: {}
}

const resposta = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_RESPOSTAS_SUCCESS':
      return {
        ...initState,
        respostas: action.dados.respostas
      };
    case 'FIND_BY_ID_RESPOSTA_SUCCESS':
      return {
        ...initState,
        resposta: { ...state.resposta, ...action.resposta }
      };
    case 'ADD_RESPOSTA_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default resposta;