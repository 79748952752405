import React, { useState } from 'react';
import { Grid, Card, Icon, Dimmer, Loader } from 'semantic-ui-react';

const BotaoRelatorio = ({ icon, onClick, label, children }) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = async () => {
    if (!loading) {
      setLoading(true);
      await onClick();
      setLoading(false);
    }
  };

  const cardContentStyle = {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Grid.Column>
      <Card className="dashboard-card-link" color="violet" size="large">
        <Card.Content style={cardContentStyle} onClick={handleOnClick}>
          {loading ? (
            <Dimmer active inverted>
              <Loader>Gerando Relatório...</Loader>
            </Dimmer>
          ) : (
            <div>
              <Card.Description><Icon name={icon} size="large" /></Card.Description>
              <br />
              <Card.Description><h3>{label}</h3></Card.Description>
            </div>
          )}
        </Card.Content>
        { children &&
          <Card.Content extra>
            {children}
          </Card.Content>
        }
      </Card>
    </Grid.Column>
  );
};

export default BotaoRelatorio;
