import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = pagamento => ({
  type: "CREATE_PAGAMENTO_SUCCESS",
  pagamento
});

const findSuccess = dados => ({
  type: "FIND_PAGAMENTOS_SUCCESS",
  dados
});

const addError = errors => ({
  type: "ADD_PAGAMENTO_ERROR",
  errors
});

export const create = dados => async dispatch => {
  return api.pagamento
    .create(dados)
    .then(data => {
      dispatch(createSuccess(data));
      return data;
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const find = condominioId => async dispatch => {
  return api.pagamento
    .find(condominioId)
    .then(data => {
      return dispatch(findSuccess(data));
    })
    .catch(error => {
      dispatch(addError(errorHandler.getError(error)));
    });
};
