import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Redirect, Link } from 'react-router-dom';
import { Divider, Loader, Button, Icon, List, Responsive } from 'semantic-ui-react';
import { find, findByIdAndRemove, findByIdAndSend } from '../../../actions/notificacao';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { findById as findCondominioById } from "../../../actions/condominio";
import Notificacao from './Notificacao';
import CondominioVencido from '../admin/CondominioVencido';
import api from '../../../api';
import { base64toBlob } from '../../../utils/fileSaverHelper';

class Notificacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
    };
  }

  componentDidMount = async () => {
    await this.props.findCondominioById(this.props.match.params.id);
    await this.props.find(this.props.condominio._id);
    this.setState({ loading: false, errors: { ...this.props.errors, ...this.props.errorsNotificacao } })
  };

  sendAction = async id => {
    this.setState({ loading: true });
    await this.props.findByIdAndSend(id);
    await this.props.find(this.props.condominio._id);
    this.setState({ loading: false, errors: { ...this.props.errors, ...this.props.errorsNotificacao } });
  };

  removeAction = async id => {
    this.setState({ loading: true });
    await this.props.findByIdAndRemove(id);
    await this.props.find(this.props.condominio._id);
    this.setState({ loading: false, errors: { ...this.props.errors, ...this.props.errorsNotificacao } })
  };

  onClickRel = async () => {
    const rel = await api.notificacao.relatorioGeral(this.props.condominio._id);
    saveAs(await base64toBlob(rel, 'application/pdf'), 'relatorio-geral-notificacoes.pdf');
  };

  render() {
    const { loading, errors } = this.state;
    const { notificacoes, condominio } = this.props;
    return (
      <div>
        {!!errors.global && <Redirect to="/" />}
        <MenuHorizontal />
        <div className="corpo-dashboard">
          <MenuVertical condominio={condominio} />
          <CondominioVencido condominio={condominio}>
            <Responsive minWidth={768}>
              <Button
                className="btn-ui-1"
                as={Link}
                to={`/condominios/${condominio._id}/notificacoes/cadastrar`}
                size="large"
              >
                <Icon name="plus" /> Cadastrar Convocação Digital / Notificações
              </Button>
              <Button
                color="blue"
                size="large"
                style={{ marginLeft: 15 }}
                onClick={this.onClickRel}
              >
                <Icon name="clipboard outline" /> Relatório de Notificações
              </Button>
            </Responsive>
            <Responsive maxWidth={767}>
              <Button
                className="btn-ui-1"
                as={Link}
                to={`/condominios/${condominio._id}/notificacoes/cadastrar`}
                size="large"
                style={{ width: '100%' }}
              >
                <Icon name="plus" /> Cadastrar Notificação
              </Button>
              <Button
                color="blue"
                size="large"
                style={{ width: '100%', marginTop: 10 }}
                onClick={this.onClickRel}
              >
                <Icon name="clipboard outline" /> Relatório de Notificações
              </Button>
            </Responsive>
          </CondominioVencido>
          <Divider horizontal>Notificações</Divider>
          {loading ?
              <Loader active inline="centered" style={{ marginTop: 50 }} />
            :
            !!notificacoes.length ?
              <List verticalAlign="middle">
                {notificacoes.map((notificacao, i) => (
                  <Notificacao key={i} notificacao={notificacao} sendAction={this.sendAction} removeAction={this.removeAction} />
                ))}
              </List>
              :
              <div className="empty-illustration-1">
                <img src="/images/illustrations/lupa-casa.svg" alt="Nenhuma Notificação Encontrada" />
              </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  condominio: state.condominio.condominio,
  notificacoes: state.notificacao.notificacoes,
  errors: state.condominio.errors,
  errorsNotificacao: state.notificacao.errors
});

const mapDispatchToProps = dispatch => bindActionCreators({
  find,
  findByIdAndRemove,
  findByIdAndSend,
  findCondominioById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notificacoes);
