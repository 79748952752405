import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const ModerateButton = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(p => !p);
  
  const action = props.message.moderado ? 'Exibir' : 'Ocultar';
  const style = { textDecoration: props.message.moderado ? 'line-through' : 'none' };

  const confirm = () => {
    setDropdownOpen(false);
    props.moderate(props.message);
  };

  const cancel = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <div className="message-dropdown">
        <span style={style}>{props.message.user.nome}</span>
        <button onClick={toggleDropdown}>
          <Icon name="angle down" />
        </button>
        {dropdownOpen && (
          <ul className="message-dropdown-content">
            <li>
              <Modal
                trigger={<Button>{action}</Button>}
                header='Confirmação'
                content={`Deseja ${action.toLowerCase()} o comentário selecionado no chat para todos os usuários?`}
                actions={[
                  { key:'cancel', content: 'Não', className: 'btn-ui-1 btn-ui-1-basic', onClick: cancel },
                  { key: 'done', content: 'Sim', className: 'btn-ui-1', onClick: confirm }
                ]}
              />
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default ModerateButton;
