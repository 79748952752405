import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = notificacao => ({
  type: 'CREATE_NOTIFICACAO_SUCCESS',
  notificacao
});

const findSuccess = (dados) => ({
  type: 'FIND_NOTIFICACOES_SUCCESS',
  dados,
})

const findByIdSuccess = notificacao => ({
  type: 'FIND_BY_ID_NOTIFICACAO_SUCCESS',
  notificacao
})

const findByIdAndUpdateSuccess = notificacao => ({
  type: 'UPDATE_NOTIFICACAO_SUCCESS',
  notificacao
})

const findByIdAndRemoveSuccess = notificacao => ({
  type: 'REMOVE_NOTIFICACAO_SUCCESS',
  notificacao
});

const findByIdAndSendSuccess = notificacao => ({
  type: 'SEND_NOTIFICACAO_SUCCESS',
  notificacao
});

const addError = errors => ({
  type: 'ADD_NOTIFICACAO_ERROR',
  errors
})

export const create = (dados) => async dispatch => {
  return api.notificacao.create(dados).then(data => {
    return dispatch(createSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findById = id => async dispatch => {
  return api.notificacao.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const find = condominio => async dispatch => {
  return api.notificacao.find(condominio).then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.notificacao.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndRemove = id => async dispatch => {
  return api.notificacao.findByIdAndRemove(id).then(data => {
    return dispatch(findByIdAndRemoveSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndSend = id => async dispatch => {
  return api.notificacao.findByIdAndSend(id).then(data => {
    return dispatch(findByIdAndSendSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}
