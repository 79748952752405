import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

class Rodape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: {
        rodape: {}
      }
    };
  }

  componentDidMount = () => {
    const wrappedElement = document.getElementById("rodape");
    if (window.innerHeight > wrappedElement.getBoundingClientRect().bottom) {
      this.setState({
        styles: {
          rodape: {
            position: "absolute",
            left: "0",
            bottom: "0",
            width: "100%"
          }
        }
      });
    }
  };

  render() {
    return (
      <div
        className="rodape text-center bg-color-primary"
        id="rodape"
        style={this.state.styles.rodape}
      >
        <Grid verticalAlign="middle" columns={3} doubling stackable>
          <Grid.Column>
            <img
              src="/images/logo-vote-condominio-escrita-branca.svg"
              alt="Logo Vote Condomínio"
              style={{ maxWidth: "200px", width: "80%" }}
            />
          </Grid.Column>
          <Grid.Column>
            <Link to="/politica-de-privacidade" className="rodape-link">
              Política de privacidade
            </Link>
          </Grid.Column>
          <Grid.Column>
            <div>
              {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="rodape-link">
              <Icon name="facebook" size="big" />
            </a>&nbsp;
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="rodape-link">
              <Icon name="instagram" size="big" />
            </a> */}
              <p className="rodape-text">
                © 2017 - 2019 APP4ALL TECNOLOGIA DA INFORMAÇÃO LTDA.
              </p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Rodape;
