import { initState as responsavelInitState } from "./responsavelReducer";

const initState = {
  unidade: {
    bloco: "",
    numero: "",
    descricao: "",
    condominio: "",
    ativo: true,
    bloqueadoVotacao: false,
    fracao: 1,
    responsavel: { ...responsavelInitState.responsavel }
  },
  unidades: [],
  habilitado: true,
  errors: {}
};

const unidade = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_UNIDADES_SUCCESS":
      return {
        ...initState,
        unidades: action.dados.unidades,
      };
    case "CREATE_UNIDADE_SUCCESS":
      return {
        ...initState,
        unidades: [action.unidade, ...state.unidades],
      };
    case "FIND_BY_ID_UNIDADE_SUCCESS":
      return {
        ...initState,
        unidade: { ...state.unidade, ...action.unidade }
      };
    case "UPDATE_UNIDADE_SUCCESS":
      return {
        ...initState,
        unidades: state.unidades.map(unidade => {
          if (unidade._id === action.unidade._id) return action.unidade;
          return unidade;
        })
      };
    case "ADD_UNIDADE_ERROR":
      return {
        ...state,
        errors: action.errors
      };
      case "VALIDAR_QUANTIDADE_UNIDADE_SUCCESS":
        return {
          ...state,
          habilitado: action.habilitado
        };
      case "GERAR_NOVO_HASH_UNIDADE_SUCCESS":
        return {...state, unidade: action.unidade};
    default:
      return state;
  }
};

export default unidade;
