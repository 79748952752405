import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './assets/locales/en/translation.json'
import es from './assets/locales/es/translation.json'
import ptBR from './assets/locales/pt-br/translation.json'

i18n  
  .use(LanguageDetector)  
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },    
    resources:{
      en,
      es,
      'pt-BR': ptBR
    }
  });


export default i18n;