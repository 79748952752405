import React, { useEffect, useState } from 'react';
import MenuHorizontal from '../../layouts/MenuHorizontal';
import MenuVertical from '../../layouts/MenuVertical';
import { useDispatch } from 'react-redux';
import { Container, Transition, Icon } from 'semantic-ui-react';
import Erro from '../../layouts/mensagens/Erro';
import { Link, Redirect } from 'react-router-dom';
import { updateUsuario } from '../../../actions/auth';
import api from '../../../api';

const PagamentoSucesso = ({ match }) => {
  const dispatch = useDispatch();

  const [condominio, setCondominio] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        setCondominio(await api.condominio.findSimpleById(match.params.id));
      } catch (e) {
        setErrors(e.data.errors);
      }
      dispatch(updateUsuario());
    };
    init();
  }, [dispatch, match.params.id]);

  return (
    <div>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard-mini">
        <MenuVertical condominio={condominio} />
        <Container style={{ padding: 20 }}>
          <Erro text={errors.error} tipo="block" />
          <Transition
            duration={{ show: 1200, hide: 0 }}
            animation="fade up"
            transitionOnMount={true}
          >
            <div
              style={{
                textAlign: 'center',
                marginTop: 40
              }}
            >
              <Icon
                name="check circle outline"
                size="massive"
                color="green"
              />
              <br />
              <h3>Pagamento realizado com sucesso!</h3>
              <Link
                to={`/condominios/${condominio._id}`}
                size="small"
              >
                Ir para a Visão Geral
              </Link>
            </div>
          </Transition>
        </Container>
      </div>
    </div>
  );
};

export default PagamentoSucesso;
