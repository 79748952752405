import React, { useState } from 'react';
import { Modal, Popup, Button, Icon, Header } from 'semantic-ui-react';
import Sucesso from '../../layouts/mensagens/Sucesso';
import api from '../../../api';

const ModalFinalizarVotacao = ({ disabled, loadingVotacao, votacao, condominio, updateVotacao }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const finalizarVotacao = async () => {
    setLoading(true);
    try {
      const result = await api.votacao.finalizarVotacaoV2(votacao._id, condominio._id);
      updateVotacao(result);
      setSucesso(true);
    } catch (e) {}
    setLoading(false);
  };

  return (
    <Modal
      trigger={
        <Popup
          trigger={
            disabled ? (
              <Button className="btn-ui-1" disabled={true} loading={loadingVotacao}>
                <Icon name='lock' /> Votação Finalizada
              </Button>
            ) : (
              <Button className="btn-ui-1" onClick={handleOpen}>Finalizar Votação e Liberar Resultados</Button>
            )
          }
          content={
            <p>
              Ao finalizar uma votação os resultados estarão disponíveis imediatamente para todos os usuários e você não poderá ativá-la novamente
            </p>
          }
          size="large"
          position="bottom center"
        />
      }
      open={modalOpen}
      onClose={handleClose}
      size='small'
    >
      {sucesso ? (
        <Modal.Content>
          <Sucesso style={{ fontSize: "1.5em" }} tipo="block" size="big">
            <p>Votação finalizada com sucesso</p>
            <br />
            <Button className="btn-ui-1" inverted size="large" onClick={handleClose}>Voltar</Button>
          </Sucesso>
        </Modal.Content>
      ) : (
        <>
          <Header content="Deseja finalizar essa votação?" />
          <Modal.Content>
            <p style={{ fontSize: "1.1em" }}>
                Esta ação bloqueia a votação não permitindo mais votos e libera os resultados imediatamente para todos os usuários e você não poderá ativá-la novamente
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button className="btn-ui-1 btn-ui-1-basic" onClick={handleClose} disabled={loading}>
              <Icon name='remove' /> Cancelar
            </Button>
            <Button className="btn-ui-1" onClick={finalizarVotacao} loading={loading} disabled={loading}>
              <Icon name='checkmark' /> Finalizar Votação e Liberar Resultados
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default ModalFinalizarVotacao;
