import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from '../../layouts/MenuVertical';
import { Divider, Grid, Loader, Input, Segment, Responsive, Pagination } from "semantic-ui-react";
import Responsavel from './Responsavel';
import api from '../../../api';

const Responsaveis = ({ match: { params } }) => {
  const [condominio, setCondominio] = useState({});
  const [responsaveis, setResponsaveis] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
  const [pagination, setPagination] = useState({
    dados: [],
    paginaAtual: 1,
    totalDePaginas: 0,
    registrosPorPagina: 18,
  });

  useEffect(
    () => {
      const init = async () => {
        try {
          const cond = await api.condominio.findSimpleById(params.id);
          setCondominio(cond);

          const resp = await api.responsavel.find(cond._id).then(res => res.responsaveis);
          setResponsaveis(resp);

          setRegistrosFiltrados(resp);
          updatePagination(resp);
          setLoading(false);
        } catch (e) {
          setErrors(e.data.errors);
        }
      };

      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const containsInField = value => {
    const val = value.toLowerCase();
    const has = field => field.toLowerCase().indexOf(val) !== -1;
    return it => has(it.nome) || has(it.email) || has(it.documento) || has(it.celular);
  };

  const onChangeSearch = e => {
    const dados = responsaveis.filter(containsInField(e.target.value));
    setRegistrosFiltrados(dados);
    updatePagination(dados);
  };

  const updatePagination = dados =>
    setPagination(p => ({
      ...p,
      paginaAtual: 1,
      dados: Array.from(Array(p.registrosPorPagina), (_, i) => dados[i]).filter(it => it !== undefined),
      totalDePaginas: Math.ceil(dados.length / p.registrosPorPagina),
    }));

  const changePage = (_, e) => {
    window.scrollTo(0, 0);
    setPagination(p => ({
      ...p,
      paginaAtual: e.activePage,
      dados: Array.from(
        Array(p.registrosPorPagina),
        (_, i) => registrosFiltrados[i + ((e.activePage - 1) * p.registrosPorPagina)])
          .filter(it => it !== undefined),
    }));
  };

  const getContent = () =>
    pagination.dados.length > 0 ? (
      <>
        <Grid columns={3} doubling stackable style={{ marginTop: "20px" }}>
          {pagination.dados.map((responsavel, i) =>
            <Grid.Column key={responsavel._id}>
              <Responsavel responsavel={responsavel} condominio={condominio} />
            </Grid.Column>
          )}
        </Grid>
        {pagination.totalDePaginas > 1 && (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Pagination activePage={pagination.paginaAtual} totalPages={pagination.totalDePaginas} onPageChange={changePage} />
          </div>
        )}
      </>
    ) : (
      <div className="empty-illustration-1">
        <img src="/images/illustrations/lupa-casa.svg" alt="Nenhum Responsável Encontrado" />
      </div>
    );

  return (
    <div>
      {!!errors.global && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} />
        <Segment textAlign="center" basic>
          <Responsive minWidth={800}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Buscar Proprietário (Nome, Email, Documento e Celular)"
              style={{ width: 400 }}
              onChange={onChangeSearch}
            />
          </Responsive>
          <Responsive maxWidth={799}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Buscar Proprietário"
              onChange={onChangeSearch}
            />
          </Responsive>
        </Segment>
        <Divider horizontal>Proprietários</Divider>
        {loading ? <Loader active inline='centered' style={{ marginTop: "50px" }} /> : getContent()}
      </div>
    </div>
  );
};

export default Responsaveis;
