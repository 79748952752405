import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from '../../layouts/MenuVertical';
import { Grid, Card, Icon } from "semantic-ui-react";
import moment from 'moment';
import api from "../../../api";

const DashboardCondominio = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [condominio, setCondominio] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(
    () => {
      const init = async () => {
        try {
          setCondominio(await api.condominio.findSimpleById(match.params.id));
          setLoading(false);
        } catch (e) {
          setErrors(e.data.errors);
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isCondominioInativo = () =>
    condominio._id && (!condominio.dataVencimento || moment(condominio.dataVencimento) < moment());

  const isRenovacao = () =>
    isCondominioInativo() && condominio.dataPagamento && condominio.dataVencimento;

  return (
    <div>
      {(!!errors.global) && <Redirect to="/" />}
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical condominio={condominio} loading={loading} />
        {isCondominioInativo() && (
          <Card as={Link} to={`/condominios/${condominio._id}/comprar`} className="ui large positive button" size="large" style={{ width: '100%' }}>
            <Card.Content style={{ textAlign: "center" }}>
              <Card.Description>
                <h3 style={{ color: '#fff' }}>
                  <Icon name="credit card" size="large" style={{ display: 'inline-block', verticalAlign: 'top' }} />
                  <span style={{ display: 'inline-block', marginTop: 3, marginLeft: 5 }}>
                    {isRenovacao() ? 'Renovar' : 'Ativar'} Condomínio / Sociedade / Associação
                  </span>
                </h3>
              </Card.Description>
            </Card.Content>
          </Card>
        )}
        <Grid stackable columns={3} doubling>
          <Grid.Column>
            <Card as={Link} to={`/condominios/editar/${condominio._id}`} className="dashboard-card-link" color="violet" size="large">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="pencil" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Editar Dados do Condomínio / Sociedade / Associação</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/unidades`} className="dashboard-card-link" color="pink">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="home" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Gerenciar Unidades</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/responsaveis`} className="dashboard-card-link" color="blue">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="group" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Gerenciar Proprietários</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/votacoes`} className="dashboard-card-link" color="green">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="calendar check" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Gerenciar Votações</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/notificacoes`} className="dashboard-card-link" color="orange">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="alarm" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Gerenciar Convocação Digital / Notificações</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/historico`} className="dashboard-card-link" color="yellow">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="clipboard list" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Histórico de Pagamentos</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card as={Link} to={`/condominios/${condominio._id}/importacao`} className="dashboard-card-link" color="brown">
              <Card.Content style={{ textAlign: "center" }}>
                <Card.Description><Icon name="file excel" size="large" /></Card.Description>
                <br />
                <Card.Description><h3>Importar Arquivo CSV</h3></Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};

export default DashboardCondominio;
