const initState = {
  pergunta: {
    pergunta: "Pergunta?",
    respostas: [],
    blocos: [],
  },
  perguntas: [],
  errors: {}
};

const pergunta = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_PERGUNTAS_SUCCESS':
      return {
        ...initState,
        perguntas: action.dados.perguntas
      };
    case 'CREATE_PERGUNTA_SUCCESS':
      return {
        ...initState,
        perguntas: [action.pergunta, ...state.perguntas]
      };
    case 'FIND_BY_ID_PERGUNTA_SUCCESS':
      return {
        ...initState,
        pergunta: { ...state.pergunta, ...action.pergunta }
      };
    case 'ADD_PERGUNTA_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default pergunta;
