import React from 'react';
import MenuHorizontal from "../layouts/MenuHorizontal";
import Rodape from '../layouts/Rodape';
import { Link, Redirect } from "react-router-dom";
import { Container, Button } from 'semantic-ui-react';

const linkPlayStore = "https://play.google.com/store/apps/details?id=br.com.votecondominio.app";
const linkAppStore = "https://apps.apple.com/br/app/vote-condom%C3%ADnio/id1478410123";

const Home = (props) => {
  return (
    <div id="home">
      <Redirect to="/login" />
      <MenuHorizontal {...props} />
      <div className="pagina-inicial">
        <div className="pagina-inicial-opacidade">
          <Container className="text-center">
            <div className="pagina-inicial-header text-center">
              <h1 style={{
                fontSize: '36pt',
                padding: '10px',
                fontWeight: '400px',
                lineHeight: '40pt',
                textShadow: '1px 1px 4px #444'
              }}>Vote Condomínio</h1>
              <h3 style={{ padding: '0px', marginTop: '0px', textShadow: '1px 1px 4px #444' }}>Uma forma simples e descomplicada de votar em reuniões de condomínio</h3>
              <br /><br />
              <Link to="/cadastro">
                <Button size="large" className="btn-ui-1">Cadastre-se</Button>
              </Link>
            </div>
          </Container>
        </div>
      </div>
      <div className="div-info text-center bg-color-primary">
        <h2>Baixar agora</h2>
        <div>
          <a href={linkPlayStore} target="_blank" rel="noopener noreferrer">
            <img src="/images/badges/google_play_badge.png" alt="Badge Google Play" className="badge-loja" />
          </a>
          &nbsp;
          <a href={linkAppStore} target="_blank" rel="noopener noreferrer">
            <img src="/images/badges/app_store_badge.svg" alt="Badge App Store" className="badge-loja" />
          </a>
        </div>
      </div>
      <div id="vantagens" />
      <Rodape />
    </div >
  )
}

export default Home
