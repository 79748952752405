const initState = {
  contato: {
    nome: "",
    email: "",
    telefone: "",
    mensagem: "",
  },
  errors: {}
}

const contato = (state = initState, action = {}) => {
  switch (action.type) {
    case 'EMAIL_CONTATO_SUCCESS':
      return {
        ...initState,
      };
    case 'ADD_CONTATO_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default contato;