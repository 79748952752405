import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import MenuVertical from "../../layouts/MenuVertical";
import { Grid, Icon, Divider, Button, Loader } from "semantic-ui-react";
import Condominio from "./Condominio";
import api from "../../../api";

const Condominios = () => {
  const [loading, setLoading] = useState(true);
  const usuario = useSelector(state => state.user.user);
  const [condominios, setCondominios] = useState([]);
  const [habilitado, setHabilitado] = useState(true);

  useEffect(
    () => {
      const init = async () => {
        try {
          setCondominios(await api.condominio.findAll());
          setHabilitado(await api.condominio.validarQuantidade().then(it => it.habilitado));
          setLoading(false);
        } catch (e) {
          //
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getContent = () =>
    !!condominios.length ? (
      <Grid columns={3} doubling stackable style={{ marginTop: "20px" }}>
        {condominios.map((condominio, i) => (
          <Grid.Column key={condominio._id}>
            <Condominio
              condominio={condominio}
              usuario={usuario}
              animation={(i + 1) * 300 + 300}
            />
          </Grid.Column>
        ))}
      </Grid>
    ) : (
      <div className="empty-illustration-1">
        <img
          src="/images/illustrations/lupa-casa.svg"
          alt="Nenhum Condomínio / Sociedade / Associação Encontrado"
        />
      </div>
    );

  return (
    <div>
      <MenuHorizontal />
      <div className="corpo-dashboard">
        <MenuVertical page="condominios" />
        <div style={styles.divActions}>
          {habilitado ? (
            <Button
              className="btn-ui-1"
              as={Link}
              to={"/condominios/cadastrar"}
            >
              <Icon name="plus" /> Cadastrar Condomínios / Sociedades / Associações
            </Button>
          ) : (
            <>
              <span style={styles.span}>Limite de Condomínios / Sociedades / Associações do modo demonstração atingido.</span>
              {condominios.length > 0 && (
                <Button
                  className="btn-ui-1"
                  as={Link}
                  to={`/condominios/${condominios[0]._id}/comprar`}
                >
                  Ativar Condomínio / Sociedade / Associação
                </Button>
              )}
            </>
          )}
        </div>
        <Divider horizontal>Condomínios / Sociedades / Associações</Divider>
        {loading ? <Loader active inline="centered" style={{ marginTop: "50px" }} /> : getContent()}
      </div>
    </div>
  );
}

const styles = {
  divActions: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "35px"
  },
  span: {
    display: 'inline-block',
    width: '100%',
    marginBottom: 10,
    fontSize: 18
  }
};

export default Condominios;
