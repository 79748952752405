import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Icon, Label, Responsive, Transition, Image, Grid } from 'semantic-ui-react';
import { getPrimeiroNome } from "../../utils/formatador";
import { version } from "../../../package.json"

const VERSION = `v ${version}`;

const MenuHorizontal = props => {
  const [{ token, user }, condominio] = useSelector(state => [state.user, state.condominio.condominio]);

  const [visible, setVisible] = useState(false);

  const drawer = () => setVisible(p => !p);

  const getAutenticado = () => (
    <div style={styles.normalize}>
      <div>
        <Menu fixed='top' className="menu-horizontal" secondary style={styles.menu}>
          <Menu.Item position="left">
            <Link to="/">
              <img
                src="/images/logo-vote-condominio.png"
                alt="Vote Condomínio logo"
                height="48"
                style={{ marginTop: "4px" }}
              />
              {user.logotipo && !!user.logotipo.url && (
                <img src={user.logotipo.url} alt={user.nome} style={styles.logotipo} />
              )}
            </Link>
            <Responsive maxWidth={767}>
              {!!condominio && !!condominio.nome && <span style={styles.tituloLogo}>{condominio.nome}</span>}
            </Responsive>
          </Menu.Item>
          <Responsive as={Menu.Menu} minWidth={768} position='right' style={styles.menumenu}>
            {!!props.backLink && (
              <Menu.Item name="fale-conosco">
                <Link to={`${props.backLink}`} className="menu-link menu-item">
                  <Icon name="arrow left" /> Voltar
                </Link>
              </Menu.Item>
            )}
            {!!props.backFunction && (
              <Menu.Item name="fale-conosco">
                <span onClick={props.backFunction} className="menu-link menu-item">
                  <Icon name="arrow left" /> Voltar
                </span>
              </Menu.Item>
            )}
            <Menu.Item name="selecao-condominio">
              <Link id="selecao-condominio" to="/" className="menu-link menu-item">
                <Icon name="building" /> Selecionar Condomínio / Sociedade / Associação
              </Link>
            </Menu.Item>
            <Menu.Item name="perfil">
              <Link id="perfil" to="/perfil" className="menu-link menu-item">
                {user.fotoPerfil ? <Image src={user.fotoPerfil} avatar /> : <Icon name="user circle outline" />} {getPrimeiroNome(user.nome)}
              </Link>
            </Menu.Item>
            <Menu.Item name="ajuda">
              <Link id="ajuda" to="/ajuda" className="menu-link menu-item">
                <Icon name="help circle" /> Ajuda
              </Link>
            </Menu.Item>
            <Menu.Item name="fale-conosco">
              <Link id="fale-conosco" to="/fale-conosco" className="menu-link menu-item">
                <Icon name="envelope" /> Fale Conosco
              </Link>
            </Menu.Item>
            <Menu.Item name="logout">
              <Link id="logout" to="/logout" className="menu-link menu-item">
                <Icon name="sign-out" />Sair
              </Link>
            </Menu.Item>
            <Menu.Item name="version">
              <Label color="violet">
                {VERSION}
              </Label>
            </Menu.Item>
          </Responsive>
          <Responsive as={Menu.Menu} maxWidth={767} position='right' style={styles.menumenu}>
            <Menu.Item className="menu-item">
              <span className="menu-link-icone" onClick={drawer}>
                <Icon name={!visible ? "bars" : "close"} size="large" />
              </span>
            </Menu.Item>
          </Responsive>
        </Menu>
        <Transition duration={{ show: 400, hide: 250 }} animation="fade left" size='huge' visible={visible}>
          <div style={styles.drawer}>
            {!!condominio && !!condominio.nome && (
              <div className="text-center" style={styles.itemMenuInfo}>
                <Grid verticalAlign="middle" columns={2}>
                  <Grid.Column>
                    <h3>{condominio.unidades.length}</h3>
                    <p>Unidades</p>
                  </Grid.Column>
                  <Grid.Column style={{ textAlign: "left" }}>
                    <img src="/images/illustrations/unidades.svg" style={{ width: "80%", maxWidth: "120px", margin: "auto" }} alt="Unidades" />
                  </Grid.Column>
                </Grid>
              </div>
            )}
            <div>
              <Link id="perfil" to="/perfil" className="menu-link-drawer">
                <Icon name="user circle outline" /> Perfil
              </Link>
            </div>
            <div>
              <Link id="perfil" to="/" className="menu-link-drawer">
                <Icon name="home" /> Condomínios
              </Link>
            </div>
            {!!condominio && !!condominio.nome && (
              <>
                <div>
                  <Link id="perfil" to={`/condominios/${condominio._id}/unidades`} className="menu-link-drawer">
                    <Icon name="building" /> Unidades
                  </Link>
                </div>
                <div>
                  <Link id="perfil" to={`/condominios/${condominio._id}/responsaveis`} className="menu-link-drawer">
                    <Icon name="group" /> Proprietários
                  </Link>
                </div>
                <div>
                  <Link id="perfil" to={`/condominios/${condominio._id}/votacoes`} className="menu-link-drawer">
                    <Icon name="calendar check" /> Votações
                  </Link>
                </div>
                <div>
                  <Link id="notificacao" to={`/condominios/${condominio._id}/notificacoes`} className="menu-link-drawer">
                    <Icon name="alarm" /> Notificações
                  </Link>
                </div>
                <div>
                  <Link id="perfil" to={`/comprar`} className="menu-link-drawer">
                    <Icon name="credit card" /> Comprar Unidades
                  </Link>
                </div>
              </>
            )}
            <br />
            <div>
              <Link id="ajuda" to="/ajuda" className="menu-link-drawer">
                <Icon name="help circle" /> Ajuda
              </Link>
            </div>
            <div>
              <Link id="fale-conosco" to="/fale-conosco" className="menu-link-drawer">
                <Icon name="envelope" /> Fale Conosco
              </Link>
            </div>
            <div>
              <Link id="logout" to="/logout" className="menu-link-drawer">
                <Icon name="sign-out" /> Sair
              </Link>
            </div>
            <div>
              <Label color="violet" style={{ marginLeft: 15, marginTop: 5}}>
                {VERSION}
              </Label>
            </div>
          </div>
        </Transition>
      </div>
      <Transition duration={{ show: 300, hide: 200 }} animation="fade" size='huge' visible={visible}>
        <div style={styles.bgDrawer} onClick={drawer}></div>
      </Transition>
    </div>
  );

  const getNaoAutenticado = () => (
    <div style={styles.normalize}>
      <div>
        <Menu fixed='top' className="menu-horizontal" secondary style={styles.menu}>
          <Menu.Item position="left">
            <a href="https://www.votecondominio.com.br"><img src="/images/logo-vote-condominio.png" alt="Vote Condomínio logo" height="48" style={{ marginTop: "4px" }} /></a>
          </Menu.Item>
          <Responsive as={Menu.Menu} minWidth={768} position='right' style={styles.menumenu}>
            <Menu.Item name="ajuda">
              <Link id="ajuda" to="/ajuda" className="menu-link menu-item">
                <Icon name="help circle" /> Ajuda
              </Link>
            </Menu.Item>
            <Menu.Item name="fale-conosco">
              <Link id="fale-conosco" to="/fale-conosco" className="menu-link menu-item">
                <Icon name="envelope" /> Fale Conosco
              </Link>
            </Menu.Item>
            <Menu.Item name="cadastro">
              <Link id="cadastro" to="/cadastro" className="menu-link menu-item">
                <Icon name="building" /> Cadastre-se
              </Link>
            </Menu.Item>
            <Menu.Item name="login">
              <Link id="login" to="/login" className="menu-link menu-item">
                <Icon name="sign-in" /> Entrar
              </Link>
            </Menu.Item>
          </Responsive>
          <Responsive as={Menu.Menu} maxWidth={767} position='right' style={styles.menumenu}>
            <Menu.Item className="menu-item">
              <span className="menu-link-icone" onClick={drawer}>
                <Icon name={visible ? "close" : "bars"} size="large" />
              </span>
            </Menu.Item>
          </Responsive>
        </Menu>
        <Transition duration={{ show: 400, hide: 250 }} animation="fade left" size='huge' visible={visible}>
          <div style={styles.drawer}>
            <div>
              <Link id="ajuda" to="/ajuda" className="menu-link menu-link-drawer">
                <Icon name="help circle" /> Ajuda
              </Link>
            </div>
            <div>
              <Link id="fale-conosco" to="/fale-conosco" className="menu-link menu-link-drawer">
                <Icon name="envelope" /> Fale Conosco
              </Link>
            </div>
            <div>
              <Link id="cadastro" to="/cadastro" className="menu-link menu-link-drawer">
                <Icon name="building" /> Cadastre-se
              </Link>
            </div>
            <div>
              <Link id="login" to="/login" className="menu-link menu-link-drawer">
                <Icon name="sign-in" /> Entrar
              </Link>
            </div>
          </div>
        </Transition>
      </div>
      <Transition duration={{ show: 300, hide: 200 }} animation="fade" size='huge' visible={visible}>
        <div style={styles.bgDrawer} onClick={drawer}/>
      </Transition>
    </div>
  );

  return !!token ? getAutenticado() : getNaoAutenticado();
};

const styles = {
  menu: {
    boxShadow: "0px 1px 4px #AAA",
    backgroundColor: "rgba(255,255,255,1)",
    zIndex: "1000",
    height: "64px"
  },
  menumenu: {
    marginRight: "10px"
  },
  normalize: {
    paddingBottom: "65px"
  },
  drawer: {
    position: "fixed",
    width: "85%",
    right: "0px",
    top: "64px",
    zIndex: 1001,
    backgroundColor: "#EEE",
    height: "100%",
    overflowY: "auto",
    paddingBottom: "60px"
  },
  bgDrawer: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,.8)",
    width: "100%",
    right: "0px",
    top: "64px",
    zIndex: 1000,
    height: "100%"
  },
  itemMenuInfo: {
    padding: "30px 10px"
  },
  tituloLogo: {
    paddingLeft: "10px",
    paddingTop: "2px",
    fontWeight: "900",
    maxWidth: "200px",
    lineHeight: "1.2",
    maxHeight: "42px",
    marginTop: "1px",
    display: "inline-block",
    overflow: "hidden"
  },
  logotipo: {
    display: 'inline-block',
    maxHeight: 50,
    marginLeft: 10
  }
};

export default MenuHorizontal;
